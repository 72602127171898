<template>
    <div>
        <ToolbarView @search="onSearch" @export="onExport" />
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex shadow-sm p-2 bg-body-tertiary rounded">
                    <div class="input-group" style="width: 300px;">
                        <span class="input-group-text">შეკვეთის №</span>
                        <input type="text" class="form-control" v-model="orderNumFilter" />
                    </div>
                    <div class="input-group ms-2" style="width: 350px;">
                        <span class="input-group-text">ტელეფონი</span>
                        <input type="text" class="form-control" v-model="phoneFilter" />
                    </div>
                    <div class="input-group ms-2" style="width: 450px;">
                        <span class="input-group-text">ფილიალი</span>
                        <select class="form-control" v-model="branchFilter">
                            <option :value="branch.id" v-for="branch in filterBranches" :key="branch.id">{{ branch.name }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <GridView class="mt-3" ref="grid" :columns="columns" :data="orderData" :grid-height="'78vh'" :context-menu-items="contextMenuItems" />
        <div class="modal" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ orderDetail.title }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <ul class="list-group">
                            <li class="list-group-item" v-for="(item, index) in orderDetail.items" :key="index">
                                <div class="w-100 d-flex justify-content-between align-items-center">
                                    <span>
                                        <span style="margin-left: 5px;">{{ item.quantity }}x</span>
                                        {{ item.product_name }}
                                    </span>
                                    <b>{{ item.price.toFixed(2) }}</b>
                                </div>
                                <div class="pl-3 mt-2" v-if="item.recipes.length > 0">
                                    <b>რეცეპტები</b>
                                    <div v-for="(recipe, r_index) in item.recipes" :key="r_index" class="mt-1 pl-2">
                                        - {{ recipe.name }}
                                    </div>
                                </div>
                                <div class="pl-3 mt-2" v-if="item.modifiers.length > 0">
                                    <b>დანამატები</b>
                                    <div v-for="(modifier, m_index) in item.modifiers" :key="m_index" class="mt-1 pl-2">
                                        {{ modifier.quantity }}x {{ modifier.name }}
                                    </div>
                                </div>
                                <div class="pl-3 mt-2" v-if="item.combo_insides.length > 0">
                                    <b>კომბო</b>
                                    <div v-for="(combo, c_index) in item.combo_insides" :key="c_index" class="mt-1 pl-2">
                                        {{ combo.inside_quantity }}x {{ combo.name }}
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <dialog id="id_refund_comment_dialog">
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <textarea class="form-control" id="id_txt_refund"></textarea>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-6 text-center">
                        <button type="button" class="btn btn-success" id="id_btn_do_refund">შესრულება</button>
                    </div>
                    <div class="col-md-6 text-center">
                        <button type="button" class="btn btn-default" id="id_btn_refund_modal_close">დახურვა</button>
                    </div>
                </div>
            </div>
        </dialog>


    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import * as bootstrap from 'bootstrap'

import request from '../../http/request'
import ToolbarView from '../../components/ToolbarView.vue'
import GridView from '../../components/GridView.vue'

const store = useStore()

const columns = [
    { headerName: 'ნომერი', field: 'num', cellDataType: 'text', cellClass: 'stringType', resizable: true },
    {
        headerName: 'შეკვეთის დრო',
        field: 'tdate',
        cellDataType: 'date',
        cellClass: 'dateType',
        resizable: true,
        sortable: true,
        valueFormatter: (params) => {
            const date = new Date(params.data.tdate)
            const day = date.getDate().toString().padStart(2, '0')
            const month = (date.getMonth() + 1).toString().padStart(2, '0')
            const year = date.getFullYear()

            const hourNum = date.getHours()

            const hour = (hourNum === 0 ? 0 : hourNum).toString().padStart(2, '0')
            const min = date.getMinutes().toString().padStart(2, '0')

            return year + '-' + month + '-' + day + ' ' + hour + ':' + min
        }
    },
    {
        headerName: 'დასრულების დრო',
        field: 'finish_date',
        cellDataType: 'date',
        cellClass: 'dateType',
        resizable: true,
        sortable: true,
        valueFormatter: (params) => {
            if (params.data.finish_date === '0001-01-01T00:00:00') return ''
            const date = new Date(params.data.finish_date)
            const day = date.getDate().toString().padStart(2, '0')
            const month = (date.getMonth() + 1).toString().padStart(2, '0')
            const year = date.getFullYear()

            const hourNum = date.getHours()

            const hour = (hourNum === 0 ? 0 : hourNum).toString().padStart(2, '0')
            const min = date.getMinutes().toString().padStart(2, '0')

            return year + '-' + month + '-' + day + ' ' + hour + ':' + min
        }
    },
    { headerName: 'მომხმარებელი', field: 'customer_name', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
    { headerName: 'ტელეფონი', field: 'customer_phone', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
    { headerName: 'თანამშ.', field: 'is_staff', cellDataType: 'boolean', cellClass: 'booleanType', resizable: true, sortable: true },
    { headerName: 'ფილიალი', field: 'branch_name', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
    { headerName: 'კერძის რაოდ.', field: 'items_count', cellDataType: 'number', cellClass: 'numberType', resizable: true, sortable: true, type: 'rightAligned' },
    { headerName: 'ღირებულება', field: 'amount', cellDataType: 'number', cellClass: 'numberType', resizable: true, sortable: true, type: 'rightAligned' },
    { headerName: 'ბონუს თანხა', field: 'bonus_amount', cellDataType: 'number', cellClass: 'numberType', resizable: true, sortable: true, type: 'rightAligned' },
    { headerName: 'სულ ჯამი', field: 'total_amount', cellDataType: 'number', cellClass: 'numberType', resizable: true, sortable: true, type: 'rightAligned' },
    { headerName: 'მიწოდება', field: 'delivery_amount', cellDataType: 'number', cellClass: 'numberType', resizable: true, sortable: true, type: 'rightAligned' },
    { headerName: 'სუბსიდია', field: 'delivery_amount_subsidy', cellDataType: 'number', cellClass: 'numberType', resizable: true, sortable: true, type: 'rightAligned' },
    {
        headerName: 'ტიპი',
        field: 'order_type',
        cellDataType: 'text',
        cellClass: 'stringType',
        resizable: true,
        sortable: true,
        valueGetter: params => {
            let type = ''
            switch (params.data.order_type) {
                case 0:
                    type = 'Dine In'
                    break
                case 1:
                    type = 'Take Away'
                    break
                case 2:
                    type = 'Delivery'
                    break
                case 4:
                    type = 'Curbside'
                    break
            }

            return type
        }
    },
    {
        headerName: 'სტატუსი',
        field: 'order_status',
        cellDataType: 'text',
        cellClass: 'stringType',
        resizable: true,
        sortable: true,
        valueGetter: params => {
            let status = ''
            switch (params.data.order_status) {
                case 0:
                    status = 'შეკვეთილი'
                    break
                case 1:
                    status = 'მიღებული'
                    break
                case 2:
                    status = 'გაუქმებული'
                    break
                case 3:
                    status = 'უარყოფილი'
                    break
                case 4:
                    status = 'მზადდება'
                    break
                case 5:
                    status = 'დამზადებულია'
                    break
                case 9:
                    status = 'დასრულებული'
                    break
                case 10:
                    status = 'დასრულებული'
                    break
            }

            return status
        }
    },
    { headerName: 'ვერსია', field: 'version', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
]

const filterBranches = ref([])

const branchFilter = ref(0)
const phoneFilter = ref()
const orderNumFilter = ref()

const orderData = ref([])
const grid = ref(null)
const orderDetail = ref({
    title: '',
    items: []
})

const contextMenuItems = (params) => {
    return [
        {
            name: 'დეტალები',
            action: () => openOrderItems(params.node.data)
        },
        {
            name: 'თანხის დაბრუნება',
            action: async () => {
                await refundOrderMoney(params.node.data.id)
            }
        },
        {
            name: 'ჩახურვა',
            action: () => statusChange(params.node.data.id)
        },
    ]
}

function refundOrderMoney (orderId) {
    const confirmResult = confirm('დაუბრუნდეს თანხა?')
    if (confirmResult) {
        var dialog = document.querySelector('#id_refund_comment_dialog')
        dialog.showModal()

        document.querySelector('#id_btn_refund_modal_close').addEventListener('click', function(e) {
            dialog.close()
        })

        document.querySelector('#id_btn_do_refund').addEventListener('click', async function(e) {
            const response = await request.postRequest(
            '/api/companyportal/refund_money',
            {
                id: orderId,
                query: document.querySelector('#id_txt_refund').value
            }
            )

            if (response.status === 200) {
                dialog.close()
                await loadData()
            }
        }.bind(this))
    }
}

function openOrderItems (order) {
    const myModalAlternative = new bootstrap.Modal('.modal', {})
    myModalAlternative.show()

    orderDetail.value.title = `შეკვეთა №${order.num}`
    orderDetail.value.items = order.items
}

async function statusChange(orderId) {
    const confirmResult = confirm('ჩაიხუროს შეკვეთა?')
    if (confirmResult) {
        const response = await request.postRequest(
            '/api/companyportal/close_order',
            {
                id: orderId
            }
        )

        if (response.status === 200) {
            await loadData()
        }
    }
}

async function loadData () {
    grid.value.showLoading()
    const response = await request.postRequest(
        '/api/companyportal/get_all_orders_for_report',
        {
            company_id: store.getters.getCompanyId,
            start_date: store.getters.getPicker.dateFrom,
            end_date: store.getters.getPicker.dateTo,
            filter: {
                'customer_phone': phoneFilter.value,
                'order_num': orderNumFilter.value,
                'branch_id': branchFilter.value
            }
        })

    if (response.status === 200) {
        orderData.value = response.data
    }
    if (grid.value != null) { grid.value.hideLoading() }
}

async function loadInitialData() {
    const response = await request.postRequest(
        '/api/companyportal/get_branches_for_params',
        {
            company_id: store.getters.getCompanyId,
        })

        filterBranches.value = [{'id': 0, 'name': '--ყველა--'}].concat(response.data)
}

async function onSearch () {
    await loadData()
}

function onExport () {
    grid.value.excelExport()
}

onMounted(async () => {
    await loadInitialData();
})

</script>

<template>
    <div>
        <ToolbarView @search="onSearch" @export="onExport" has-customer="true" @customer-selected="onCustomerSelected" />
        <GridView class="mt-3" ref="grid" :columns="columns" :data="orderData" :import-date="true"
            :context-menu-items="contextMenuItems" />
        <div class="modal fade" tabindex="-1" data-bs-backdrop="static" id="add_bonus_dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <form id="id_form_lottery_main_prize" novalidate>
                        <div class="modal-header">
                            <h5 class="modal-title">ქულის ჩუქება</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="form-floating mb-3">
                                <input type="number" class="form-control form-control-sm" id="id_point" placeholder="ქულა"
                                    required />
                                <label for="id_point">ქულა</label>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-success" @click="saveAddBonus">შენახვა</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import * as bootstrap from 'bootstrap'

import request from '../../http/request'
import ToolbarView from '../../components/ToolbarView.vue'
import GridView from '../../components/GridView.vue'

const store = useStore()

const columns = [
    { headerName: 'სახელი, გვარი', field: 'customer_name', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
    { headerName: 'ტელეფონი', field: 'phone', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
    { headerName: 'ბარათი', field: 'card_name', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
    { headerName: 'დაგრ. ქულა', field: 'collected_bonus', cellDataType: 'number', cellClass: 'numberType', resizable: true, sortable: true, type: 'rightAligned' },
    { headerName: 'დაგრ. ქულა (თანხა)', field: 'collected_bonus_amount', cellDataType: 'number', cellClass: 'numberType', resizable: true, sortable: true, type: 'rightAligned' },
    { headerName: 'გახარჯ. ქულა', field: 'spent_bonus', cellDataType: 'number', cellClass: 'numberType', resizable: true, sortable: true, type: 'rightAligned' },
    { headerName: 'გახარჯ. ქულა (თანხა)', field: 'spent_bonus_amount', cellDataType: 'number', cellClass: 'numberType', resizable: true, sortable: true, type: 'rightAligned' },
    { headerName: 'მიმდინარე ქულა', field: 'total_bonus', cellDataType: 'number', cellClass: 'numberType', resizable: true, sortable: true, type: 'rightAligned' },
    {
        headerName: 'გადახდილი თანხა', field: 'sum_of_orders', cellDataType: 'number', cellClass: 'numberType', resizable: true, sortable: true, type: 'rightAligned',
        valueFormatter: (params) => {
            return params.data.sum_of_orders.toFixed(2)
        }
    },
    {
        headerName: 'ბოლო განახლება',
        field: 'update_time',
        cellDataType: 'date',
        cellClass: 'dateType',
        resizable: true,
        sortable: true,
        valueFormatter: (params) => {
            if (params.data.update_time === null || params.data.update_time === '0001-01-01T00:00:00') return ''
            const date = new Date(params.data.update_time)
            const day = date.getDate().toString().padStart(2, '0')
            const month = (date.getMonth() + 1).toString().padStart(2, '0')
            const year = date.getFullYear()

            const hourNum = date.getHours()

            const hour = (hourNum === 0 ? 0 : hourNum).toString().padStart(2, '0')
            const min = date.getMinutes().toString().padStart(2, '0')

            return year + '-' + month + '-' + day + ' ' + hour + ':' + min
        }
    },
]

const orderData = ref([])
const grid = ref(null)

let addBonusModal, addBonusField;

let _id = 0
let customerId = 0

let bonusData;

async function loadData () {
    grid.value.showLoading()
    const response = await request.postRequest(
        '/api/companyportal/get_customer_cards_by_company', {
        company_id: store.getters.getCompanyId,
        id: customerId,
        start_date: store.getters.getPicker.dateFrom,
        end_date: store.getters.getPicker.dateTo,
    })

    if (response.status === 200) {
        response.data.forEach((row) => {
            row.collected_bonus_amount = row.collected_bonus * bonusData.bonusAmountCoef
            row.spent_bonus_amount = row.spent_bonus * bonusData.bonusAmountCoef
        });

        orderData.value = response.data
    }

    if (grid.value != null) { grid.value.hideLoading() }
}

async function onSearch () {
    await loadData()
}

function onExport () {
    grid.value.excelExport()
}

const contextMenuItems = (params) => {
    return [
        {
            name: 'ქულის ჩუქება',
            action: () => {
                _id = params.node.data.id

                addBonusField = document.querySelector('#id_point')
                addBonusField.value = 0

                addBonusModal = new bootstrap.Modal('#add_bonus_dialog', {})
                addBonusModal.show()
            }
        },
        {
            name: 'ქულების განულება',
            action: async () => {
                await clearBonus(params.node.data.id, params.node.data.bonus)
            }
        }
    ]
}

async function clearBonus (card_id, bonus) {
    if (confirm('ნამდვილად გსურთ ქულების განულება?')) {
        const response = await request.postRequest(
            '/api/companyportal/update_customer_cards_bonus',
            {
                card_id: card_id,
                bonus: -1 * bonus,
                company_id: store.getters.getCompanyId,
            }
        )
        if (response !== -1) {
            if (response.data == 0) {
                alert('ვერ მოხერხდა ქულების განულება')
            } else {
                await loadData()
            }
        } else {
            alert('ვერ მოხერხდა ქულების განულება')
        }
    }
}

async function saveAddBonus () {
    const response = await request.postRequest(
        '/api/companyportal/update_customer_cards_bonus',
        {
            card_id: _id,
            bonus: parseInt(addBonusField.value),
            company_id: store.getters.getCompanyId
        }
    )
    if (response !== -1) {
        if (response.data == 0) {
            alert('ვერ მოხერხდა გაცემა ქულის')
        } else {
            await loadData()

            addBonusField.value = 0
            addBonusModal.hide()
        }
    } else {
        alert('ბონუს ქულის გაცემა ვერ მოხერხდა')
    }
}

function onCustomerSelected (id) {
  customerId = id.value
}

async function getInitialData() {
    const response = await request.postRequest(
        '/api/companyportal/get_customer_cards_by_company_initial',
        {
            company_id: store.getters.getCompanyId
        }
    )
    bonusData = response.data
}

onMounted(() => {
    getInitialData()
})
</script>
<template>
    <div>
        <ToolbarView @search="onSearch" @export="onExport" />
        <GridView class="mt-3" ref="grid" :columns="columns" :data="orderData" />
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'

import request from '../../http/request'
import ToolbarView from '../../components/ToolbarView.vue'
import GridView from '../../components/GridView.vue'

const store = useStore()

const columns = [
  {
    headerName: 'თარიღი',
    field: 'tdate',
    cellDataType: 'dateString',
    cellClass: 'dateType',
    resizable: true,
    sortable: true,
    valueGetter: params => params.data.tdate.split('T')[0] + ' ' + params.data.tdate.split('T')[1].substr(0, 5)
  },
  { headerName: 'კომპანია', field: 'company_name', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
  { headerName: 'საქონელი', field: 'product_name', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
  { headerName: 'თანხა', field: 'price', cellDataType: 'number', cellClass: 'numberType', resizable: true, sortable: true }
]

const orderData = ref([])
const grid = ref(null)

async function loadData () {
  grid.value.showLoading()
  const response = await request.postRequest(
    '/api/companyportal/get_expenses_report',
    {
      company_id: store.getters.getCompanyId,
      start_date: store.getters.getPicker.dateFrom,
      end_date: store.getters.getPicker.dateTo
    })

  if (response.status === 200) {
    orderData.value = response.data
  }

  if (grid.value != null) { grid.value.hideLoading() }
}

async function onSearch () {
  await loadData()
}

function onExport () {
  grid.value.excelExport()
}

onMounted(() => {
  setTimeout(async () => await loadData(), 300)
})
</script>

<template>
    <div class="input-group ms-3">
        <span class="input-group-text" id="id_input_customer_addon">მომხმარებელი</span>
        <input type="text" id="id_input_customer" class="form-control" placeholder="ძებნა"
            aria-describedby="id_input_customer_addon">
    </div>
</template>

<script setup>
import request from '../http/request'

import { onMounted, defineEmits } from 'vue'
import { Autocomplete } from './autocomplete'

const emit = defineEmits(['selected'])

let customers = []

async function getCustomers () {
  const response = await request.postRequest('/api/companyportal/get_filter_customers')
  if (response.status === 200) {
    customers = response.data
  }
}

onMounted(async () => {
  await getCustomers()

  const field = document.querySelector('#id_input_customer')
  // eslint-disable-next-line no-unused-vars
  const aucomplete = new Autocomplete(field, {
    data: customers,
    maximumItems: 5,
    threshold: 1,
    showSubLabel: true,
    // eslint-disable-next-line no-unused-vars
    onSelectItem: ({ label, value }) => {
      emit('selected', { value })
    }
  })
})
</script>

<style scoped>
.dropdown-menu {
    transform: translate(200px, 40px) !important;
}
</style>

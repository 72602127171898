<template>
    <div>
        <ToolbarView @search="onSearch" @export="onExport" />
        <GridView class="mt-3" ref="grid" :columns="columns" :data="data" :import-date="true" :context-menu-items="contextMenuItems" />
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'

import request from '../../http/request'
import ToolbarView from '../../components/ToolbarView.vue'
import GridView from '../../components/GridView.vue'

const store = useStore()

const columns = [
    { headerName: 'მომხმარებელი', field: 'customer_name', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
    {
        headerName: 'შეკვეთის დრო',
        field: 'tdate',
        cellDataType: 'date',
        cellClass: 'dateType',
        resizable: true,
        sortable: true,
        valueFormatter: (params) => {
            const date = new Date(params.data.tdate)
            const day = date.getDate().toString().padStart(2, '0')
            const month = (date.getMonth() + 1).toString().padStart(2, '0')
            const year = date.getFullYear()

            const hourNum = date.getHours()

            const hour = (hourNum === 0 ? 0 : hourNum).toString().padStart(2, '0')
            const min = date.getMinutes().toString().padStart(2, '0')

            return year + '-' + month + '-' + day + ' ' + hour + ':' + min
        }
    },
    { headerName: 'მიზეზი', field: 'text', cellDataType: 'text', resizable: true, cellClass: 'stringType', },
    { headerName: 'PaymentId', field: 'payment_id', cellDataType: 'text', resizable: true, cellClass: 'stringType', },
]

async function onSearch() {
    await loadData()
}

const data = ref([])
const grid = ref(null)

const contextMenuItems = (params) => {
    return [{
        name: 'Copy',
        action: async () => await navigator.clipboard.writeText(params.node.data.text)
    }];
}

async function loadData() {
    grid.value.showLoading()
    const response = await request.postRequest(
        '/api/companyportal/get_failed_transactions', {
        start_date: store.getters.getPicker.dateFrom,
        end_date: store.getters.getPicker.dateTo
    })

    if (response.status === 200) {
        data.value = response.data
    }

    if (grid.value != null) { grid.value.hideLoading() }
}

function onExport() {
    grid.value.excelExport()
}

onMounted(() => {
    setTimeout(async () => await loadData(), 300)
})
</script>
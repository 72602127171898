<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex flex-row justify-content-between shadow-sm p-2 bg-body-tertiary rounded">
                    <button type="button" class="btn btn-success" @click="onOpenLottery(-1)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-plus-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path
                                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        ახალი გათამაშება
                    </button>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-12">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">დასახელება</th>
                            <th scope="col">დაწყება</th>
                            <th scope="col">დასრულება</th>
                            <th scope="col">მიმდინ. ტრანზ.</th>
                            <th scope="col">თანამშრ. ჩართულობა</th>
                            <th scope="col">აქტიური</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(lottery, index) in lotteriesData" :key="lottery.id" @click="onOpenLottery(index)"
                            style="cursor: pointer;">
                            <td>{{ lottery.name }}</td>
                            <td>{{ moment(lottery.start_date).format('YYYY-MM-DD') }}</td>
                            <td>
                                <template v-if="lottery.end_date">
                                    {{ moment(lottery.end_date).format('YYYY-MM-DD') }}
                                </template>
                            </td>
                            <td>{{ lottery.current_transaction }}</td>
                            <td>
                                <template v-if="lottery.staff_allowed">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                                        class="bi bi-check-circle" viewBox="0 0 16 16" style="color:green;">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path
                                            d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                    </svg>
                                </template>
                                <template v-else>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                                        class="bi bi-dash-circle" viewBox="0 0 16 16" style="color:red;">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                                    </svg>
                                </template>
                            </td>
                            <td>
                                <template v-if="lottery.is_active">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                                        class="bi bi-check-circle" viewBox="0 0 16 16" style="color:green;">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path
                                            d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                    </svg>
                                </template>
                                <template v-else>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                                        class="bi bi-dash-circle" viewBox="0 0 16 16" style="color:red;">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                                    </svg>
                                </template>
                            </td>
                        </tr>
                        <tr v-if="lotteriesData.length === 0">
                            <td colspan="5" class="text-center">
                                ჩანაწერები ვერ მოიძებნა
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="modal fade modal-lg" tabindex="-1" data-bs-backdrop="static" id="lottery_dialog">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <form id="id_form_lottery" novalidate>
                                <div class="modal-header">
                                    <h5 class="modal-title">{{ lotteryModalTitle }}</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <ul class="nav nav-tabs">
                                        <li class="nav-item">
                                            <button class="nav-link active" id="main-tab" data-bs-toggle="tab"
                                                data-bs-target="#main-tab-pane" type="button" role="tab"
                                                aria-controls="main-tab-pane" aria-selected="true">ძირითადი</button>
                                        </li>
                                        <li class="nav-item">
                                            <button class="nav-link" id="static-data-tab" data-bs-toggle="tab"
                                                data-bs-target="#static-data-tab-pane" type="button" role="tab"
                                                aria-controls="static-data-tab-pane" aria-selected="false">ტექსტი</button>
                                        </li>
                                    </ul>
                                    <div class="tab-content">
                                        <div class="tab-pane fade show active" id="main-tab-pane" role="tabpanel"
                                            aria-labelledby="main-tab-pane">

                                            <div class="form-floating mb-3 mt-1">
                                                <input type="text" class="form-control form-control-sm" id="id_lottery_name"
                                                    placeholder="დასახელება" v-model="lottery.name" required />
                                                <label for="id_lottery_name">დასახელება</label>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <div class="form-floating mb-3">
                                                        <input type="datetime-local" class="form-control form-control-sm"
                                                            id="id_lottery_start_date" placeholder="დაწყების დრო"
                                                            v-model="lottery.start_date" required />

                                                        <label for="id_lottery_start_date">დაწყების დრო</label>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div class="form-floating mb-3">
                                                        <input type="datetime-local" class="form-control form-control-sm"
                                                            id="id_lottery_end_date" placeholder="დასრულების დრო"
                                                            v-model="lottery.end_date">
                                                        <label for="id_lottery_end_date">დასრულების დრო</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <div class="form-floating mb-3">
                                                        <input type="number" class="form-control form-control-sm"
                                                            id="id_lottery_min_amount" placeholder="თანხის ქვედა ზღვარი"
                                                            v-model="lottery.min_amount" required />
                                                        <label for="id_lottery_min_amount">თანხის ქვედა ზღვარი</label>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div class="form-floating mb-3">
                                                        <input type="number" class="form-control form-control-sm"
                                                            id="id_lottery_min_amount"
                                                            placeholder="ხელახლა დაშვებიდ დრო(სთ)"
                                                            v-model="lottery.won_timeout" required />
                                                        <label for="id_lottery_min_amount">ხელახლა დაშვების დრო(სთ)</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <div class="form-check form-switch mb-3">
                                                        <input class="form-check-input" type="checkbox" role="switch"
                                                            id="id_lottery_staff_allowed" v-model="lottery.staff_allowed">
                                                        <label class="form-check-label"
                                                            for="id_lottery_staff_allowed">თანამშრომლების
                                                            მონაწილეობა</label>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div class="form-check form-switch mb-3 float-end">
                                                        <input class="form-check-input" type="checkbox" role="switch"
                                                            id="id_lottery_is_active" v-model="lottery.is_active">
                                                        <label class="form-check-label"
                                                            for="id_lottery_is_active">აქტიური</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <div class="form-check form-switch mb-3 float-end">
                                                        <input class="form-check-input" type="checkbox" role="switch"
                                                            id="id_lottery_is_test" v-model="lottery.is_test">
                                                        <label class="form-check-label" for="id_lottery_is_test">სატესტო
                                                            გათამაშება</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <ul class="nav nav-tabs">
                                                <li class="nav-item">
                                                    <button class="nav-link active" id="main-prize-tab" data-bs-toggle="tab"
                                                        data-bs-target="#main-prize-tab-pane" type="button" role="tab"
                                                        aria-controls="main-prize-tab-pane" aria-selected="true">მთავარი
                                                        პრიზი</button>
                                                </li>
                                                <li class="nav-item">
                                                    <button class="nav-link" id="products-tab" data-bs-toggle="tab"
                                                        data-bs-target="#products-tab-pane" type="button" role="tab"
                                                        aria-controls="products-tab-pane"
                                                        aria-selected="false">პროდუქტები</button>
                                                </li>
                                                <li class="nav-item">
                                                    <button class="nav-link" id="emails-tab" data-bs-toggle="tab"
                                                        data-bs-target="#emails-tab-pane" type="button" role="tab"
                                                        aria-controls="emails-tab-pane" aria-selected="false">ადმინების
                                                        ელ-ფოსტები</button>
                                                </li>
                                                <li class="nav-item">
                                                    <button class="nav-link" id="excluded-users-tab" data-bs-toggle="tab"
                                                        data-bs-target="#excluded-users-tab-pane" type="button" role="tab"
                                                        aria-controls="excluded-users-tab-pane"
                                                        aria-selected="false">გამონაკლისი
                                                        მომხმ.</button>
                                                </li>
                                                <li class="nav-item">
                                                    <button class="nav-link" id="test-users-tab" data-bs-toggle="tab"
                                                        data-bs-target="#test-users-tab-pane" type="button" role="tab"
                                                        aria-controls="test-users-tab-pane" aria-selected="false">სატესტო
                                                        მომხ.</button>
                                                </li>
                                            </ul>
                                            <div class="tab-content">
                                                <div class="tab-pane fade show active" id="main-prize-tab-pane"
                                                    role="tabpanel" aria-labelledby="main-prize-tab" tabindex="0">
                                                    <div
                                                        class="d-flex flex-row justify-content-between shadow-sm p-2 mt-1 bg-body-tertiary rounded">
                                                        <button type="button" class="btn btn-primary btn-sm"
                                                            @click="openMainPrize(-1)">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                                fill="currentColor" class="bi bi-plus-lg"
                                                                viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd"
                                                                    d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                                            </svg>
                                                            დამატება
                                                        </button>
                                                        <button type="button" class="btn btn-secondary btn-sm"
                                                            @click="onMainPrizedImport">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                                fill="currentColor" class="bi bi-file-earmark-excel"
                                                                viewBox="0 0 16 16">
                                                                <path
                                                                    d="M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z" />
                                                                <path
                                                                    d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                                                            </svg>
                                                            Excel ჩატვირთვა
                                                        </button>
                                                        <input class="form-control d-none" type="file"
                                                            id="id_file_main_prizes"
                                                            accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                                                    </div>
                                                    <div style="max-height: 350px; overflow-y: auto;">
                                                        <table class="table table-striped table-sm">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col"></th>
                                                                    <th scope="col">ტრანზაქცია</th>
                                                                    <th scope="col">დასახელება</th>
                                                                    <th scope="col"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(prize, index) in lottery.main_prizes"
                                                                    :key="prize.id" @click="openMainPrize(index)"
                                                                    style="cursor: pointer;">
                                                                    <td><img :src="'images/lottery/' + prize.product_name + '.jpg'"
                                                                            style="width: 30px; height: 20px;"
                                                                            :title="prize.product_name" />
                                                                    </td>
                                                                    <td>{{ prize.win_transaction_step }}</td>
                                                                    <td>{{ prize.product_name }}</td>
                                                                    <td class="text-center"><button type="button"
                                                                            class="btn btn-danger btn-sm"
                                                                            @click.stop="deleteMainPrize(index)">
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                width="16" height="16" fill="currentColor"
                                                                                class="bi bi-trash" viewBox="0 0 16 16">
                                                                                <path
                                                                                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                                                                <path
                                                                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                                                            </svg>
                                                                        </button></td>
                                                                </tr>
                                                                <tr v-if="lottery.main_prizes.length === 0">
                                                                    <td colspan="4" class="text-center">
                                                                        ჩანაწერები ვერ მოიძებნა
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="products-tab-pane" role="tabpanel"
                                                    aria-labelledby="products-tab" tabindex="1">
                                                    <div
                                                        class="d-flex flex-row justify-content-between shadow-sm p-2 mt-1 bg-body-tertiary rounded">
                                                        <button type="button" class="btn btn-primary btn-sm"
                                                            @click="openProduct(-1)">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                                fill="currentColor" class="bi bi-plus-lg"
                                                                viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd"
                                                                    d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                                            </svg>
                                                            დამატება
                                                        </button>
                                                        <input type="number" class="form-control form-control-sm w-25"
                                                            placeholder="ბიჯი"
                                                            v-model="lottery.product_win_transaction_step">
                                                    </div>
                                                    <div style="max-height: 350px; overflow-y: auto;">
                                                        <table class="table table-striped table-sm">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">დასახელება</th>
                                                                    <th scope="col">კომპანია</th>
                                                                    <th scope="col"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(product, index) in lottery.products"
                                                                    :key="product.id" @click="openProduct(index)"
                                                                    style="cursor: pointer;">
                                                                    <td>
                                                                        <div>{{ product.product_name }}</div>
                                                                        <small>{{ product.product_code }}</small>
                                                                    </td>
                                                                    <td>
                                                                        {{ product.company_name }}
                                                                    </td>
                                                                    <td class="text-center"><button type="button"
                                                                            class="btn btn-danger btn-sm"
                                                                            @click.stop="deleteProduct(index)">
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                width="16" height="16" fill="currentColor"
                                                                                class="bi bi-trash" viewBox="0 0 16 16">
                                                                                <path
                                                                                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                                                                <path
                                                                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                                                            </svg>
                                                                        </button></td>
                                                                </tr>
                                                                <tr v-if="lottery.products.length === 0">
                                                                    <td colspan="3" class="text-center">
                                                                        ჩანაწერები ვერ მოიძებნა
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="emails-tab-pane" role="tabpanel"
                                                    aria-labelledby="emails-tab" tabindex="2">
                                                    <div
                                                        class="d-flex flex-row justify-content-between shadow-sm p-2 mt-1 bg-body-tertiary rounded">
                                                        <button type="button" class="btn btn-primary btn-sm"
                                                            @click="openAdminEmail(-1)">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                                fill="currentColor" class="bi bi-plus-lg"
                                                                viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd"
                                                                    d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                                            </svg>
                                                            დამატება
                                                        </button>
                                                    </div>
                                                    <div style="max-height: 350px; overflow-y: auto;">
                                                        <table class="table table-striped table-sm">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">სახელი, გვარი</th>
                                                                    <th scope="col">ელ-ფოსტა</th>
                                                                    <th scope="col"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(email, index) in lottery.admin_emails"
                                                                    :key="email.id" @click="openAdminEmail(index)"
                                                                    style="cursor: pointer;">
                                                                    <td>{{ email.name }}</td>
                                                                    <td>{{ email.email }}</td>
                                                                    <td class="text-center"><button type="button"
                                                                            class="btn btn-danger btn-sm"
                                                                            @click.stop="deleteAdminEmail(index)">
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                width="16" height="16" fill="currentColor"
                                                                                class="bi bi-trash" viewBox="0 0 16 16">
                                                                                <path
                                                                                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                                                                <path
                                                                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                                                            </svg>
                                                                        </button></td>
                                                                </tr>
                                                                <tr v-if="lottery.admin_emails.length === 0">
                                                                    <td colspan="3" class="text-center">
                                                                        ჩანაწერები ვერ მოიძებნა
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="excluded-users-tab-pane" role="tabpanel"
                                                    aria-labelledby="excluded-users-tab" tabindex="1">
                                                    <div
                                                        class="d-flex flex-row justify-content-between shadow-sm p-2 mt-1 bg-body-tertiary rounded">

                                                        <div class="mb-3">
                                                            <input type="text" class="form-control form-control-sm"
                                                                id="id_lottery_customer_search_excluded_users"
                                                                placeholder="ძებნა" autocomplete="off" />
                                                        </div>
                                                    </div>
                                                    <div style="max-height: 350px; overflow-y: auto;">
                                                        <table class="table table-striped table-sm">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">სახელი, გვარი</th>
                                                                    <th scope="col"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(user, index) in lottery.excluded_users"
                                                                    :key="user.id" style="cursor: pointer;">
                                                                    <td>{{ user.customer_name }}</td>
                                                                    <td class="text-center"><button type="button"
                                                                            class="btn btn-danger btn-sm"
                                                                            @click.stop="deleteExcludedUser(index)">
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                width="16" height="16" fill="currentColor"
                                                                                class="bi bi-trash" viewBox="0 0 16 16">
                                                                                <path
                                                                                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                                                                <path
                                                                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                                                            </svg>
                                                                        </button></td>
                                                                </tr>
                                                                <tr v-if="lottery.excluded_users.length === 0">
                                                                    <td colspan="2" class="text-center">
                                                                        ჩანაწერები ვერ მოიძებნა
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="test-users-tab-pane" role="tabpanel"
                                                    aria-labelledby="test-users-tab" tabindex="1">
                                                    <div
                                                        class="d-flex flex-row justify-content-between shadow-sm p-2 mt-1 bg-body-tertiary rounded">

                                                        <div class="mb-3">
                                                            <input type="text" class="form-control form-control-sm"
                                                                id="id_lottery_customer_search_test_users"
                                                                placeholder="ძებნა" autocomplete="off" />
                                                        </div>
                                                    </div>
                                                    <div style="max-height: 350px; overflow-y: auto;">
                                                        <table class="table table-striped table-sm">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">სახელი, გვარი</th>
                                                                    <th scope="col"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(user, index) in lottery.test_users"
                                                                    :key="user.id" style="cursor: pointer;">
                                                                    <td>{{ user.customer_name }}</td>
                                                                    <td class="text-center"><button type="button"
                                                                            class="btn btn-danger btn-sm"
                                                                            @click.stop="deleteTestUser(index)">
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                width="16" height="16" fill="currentColor"
                                                                                class="bi bi-trash" viewBox="0 0 16 16">
                                                                                <path
                                                                                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                                                                <path
                                                                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                                                            </svg>
                                                                        </button></td>
                                                                </tr>
                                                                <tr v-if="lottery.test_users.length === 0">
                                                                    <td colspan="2" class="text-center">
                                                                        ჩანაწერები ვერ მოიძებნა
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="tab-pane fade" id="static-data-tab-pane" role="tabpanel"
                                            aria-labelledby="static-data-tab-pane">

                                            <div class="form-floating mb-3 mt-1">
                                                <input type="text" class="form-control form-control-sm" id="id_main_title"
                                                    placeholder="ძირითადის სათაური" v-model="lottery.static_data.main_title"
                                                    required />
                                                <label for="id_main_title">ძირითადის სათაური</label>
                                            </div>
                                            <div class="form-floating mb-3">
                                                <input type="text" class="form-control form-control-sm" id="id_main_content"
                                                    placeholder="ძირითადის ტექსტი"
                                                    v-model="lottery.static_data.main_content" required />
                                                <label for="id_main_content">ძირითადის ტექსტი</label>
                                            </div>
                                            <div class="form-floating mb-3">
                                                <input type="text" class="form-control form-control-sm" id="id_full_title"
                                                    placeholder="დეტალურის სათაური" v-model="lottery.static_data.full_title"
                                                    required />
                                                <label for="id_full_title">დეტალურის სათაური</label>
                                            </div>
                                            <div class="form-floating mb-3">
                                                <textarea class="form-control form-control-sm" id="id_how_to_win"
                                                    placeholder="როგორ მოვიგოთ ტექსტი"
                                                    v-model="lottery.static_data.how_to_win" style="height: 100px"
                                                    required></textarea>
                                                <label for="id_how_to_win">როგორ მოვიგოს ტექსტი</label>
                                            </div>
                                            <div class="form-floating mb-3">
                                                <textarea class="form-control form-control-sm" id="id_how_to_get"
                                                    placeholder="როგორ მივიღო პრიზი ტექსტი"
                                                    v-model="lottery.static_data.how_to_get" style="height: 300px"
                                                    required></textarea>
                                                <label for="id_how_to_get">როგორ მივიღო პრიზი ტექსტი</label>
                                            </div>
                                            <div class="form-floating mb-3">
                                                <textarea class="form-control form-control-sm" id="id_rules_text"
                                                    placeholder="გათამაშების წესები"
                                                    v-model="lottery.static_data.rules_text" style="height: 300px"
                                                    required></textarea>
                                                <label for="id_rules_text">გათამაშების წესები</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-success" @click="saveLottery">შენახვა</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="modal fade" tabindex="-1" data-bs-backdrop="static" id="lottery_main_prize_dialog">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <form id="id_form_lottery_main_prize" novalidate>
                                <div class="modal-header">
                                    <h5 class="modal-title">მთავარი პრიზი</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <div class="form-floating mb-3">
                                        <input type="number" class="form-control form-control-sm"
                                            id="id_lottery_main_prize_win_transaction_step" placeholder="ტრანზაქცია"
                                            v-model="mainPrize.win_transaction_step" required />
                                        <label for="id_lottery_main_prize_win_transaction_step">ტრანზაქცია</label>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input type="text" class="form-control form-control-sm"
                                            id="id_lottery_main_prize_name" placeholder="დასახელება"
                                            v-model="mainPrize.product_name" autocomplete="off" required />
                                        <label for="id_lottery_main_prize_name">დასახელება</label>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input type="file" accept="image/jpeg;image/png"
                                            class="form-control form-control-sm" placeholder="აირჩიეთ სურათი"
                                            id="id_lottery_main_prize_image" @change="addMainPrizeImage" />
                                        <label for="id_lottery_main_prize_image">სურათი</label>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="submit" class="btn btn-success" @click="saveMainPrize">შენახვა</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="modal fade" tabindex="-1" data-bs-backdrop="static" id="lottery_product_dialog">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <form id="id_form_lottery_product" novalidate>
                                <div class="modal-header">
                                    <h5 class="modal-title">პროდუქტი</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <div class="mb-3">
                                        <input type="text" class="form-control form-control-sm"
                                            id="id_lottery_product_search" placeholder="ძებნა" autocomplete="off" />
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input type="text" class="form-control form-control-sm" id="id_lottery_product_name"
                                            placeholder="დასახელება" v-model="product.product_name" autocomplete="off"
                                            readonly required />
                                        <label for="id_lottery_product_name">დასახელება</label>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input type="text" class="form-control form-control-sm" id="id_lottery_product_code"
                                            placeholder="კოდი" v-model="product.product_code" autocomplete="off" readonly
                                            required />
                                        <label for="id_lottery_product_code">კოდი</label>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input type="text" class="form-control form-control-sm" id="id_lottery_company_name"
                                            placeholder="კომპანია" v-model="product.company_name" autocomplete="off"
                                            readonly required />
                                        <label for="id_lottery_company_name">კომპანია</label>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="submit" class="btn btn-success" @click="saveProduct">შენახვა</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="modal fade" tabindex="-1" data-bs-backdrop="static" id="lottery_admin_email_dialog">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <form id="id_form_lottery_admin_email" novalidate>
                                <div class="modal-header">
                                    <h5 class="modal-title">ადმინის ელ-ფოსტა</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <div class="form-floating mb-3">
                                        <input type="text" class="form-control form-control-sm"
                                            id="id_lottery_admin_email_name" placeholder="სახელი, გვარი"
                                            v-model="adminEmail.name" autocomplete="off" required />
                                        <label for="id_lottery_admin_email_name">სახელი, გვარი</label>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input type="email" class="form-control form-control-sm"
                                            id="id_lottery_admin_email_email" placeholder="ელ-ფოსტა"
                                            v-model="adminEmail.email" required />
                                        <label for="id_lottery_admin_email_email">ელ-ფოსტა</label>
                                    </div>

                                </div>
                                <div class="modal-footer">
                                    <button type="submit" class="btn btn-success" @click="saveAdminEmail">შენახვა</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { onMounted, ref, reactive } from 'vue'
import * as bootstrap from 'bootstrap'
import moment from 'moment'
import * as XLSX from 'xlsx/dist/xlsx.full.min.js'

import request from '../../http/request'
import { Autocomplete } from '../../components/autocomplete'

const lotteryObj = {
    id: 0,
    name: null,
    start_date: moment().format('YYYY-MM-DDTHH:mm'),
    end_date: moment().add(1, 'months').format('YYYY-MM-DDTHH:mm'),
    min_amount: 20,
    won_timeout: 72,
    staff_allowed: false,
    product_win_transaction_step: 0,
    is_active: true,
    is_test: false,
    main_prizes: [],
    products: [],
    admin_emails: [],
    excluded_users: [],
    test_users: [],
    static_data: {
        id: 0,
        main_title: null,
        main_content: null,
        full_title: null,
        how_to_win: null,
        how_to_get: null,
        rules_text: null
    }
}

const mainPrizeObj = {
    id: 0,
    win_transaction_step: 0,
    product_name: null,
    img: null
}

const productObj = {
    id: 0,
    product_name: null,
    product_code: null,
    product_id: null,
    company_id: null,
    company_name: null,
    product_img: null,
}

const adminEmailObj = {
    id: 0,
    name: null,
    email: null
}

const lotteriesData = ref([])
let lotteryModalTitle = ref()

const lottery = reactive({ ...lotteryObj })
const mainPrize = reactive({ ...mainPrizeObj })
const product = reactive({ ...productObj })
const adminEmail = reactive({ ...adminEmailObj })

let mainPrizeSelectedIndex = -1
let productSelectedIndex = -1
let adminEmailSelectedIndex = -1

let mainPrizeFileField = null
let mainPrizeImageField = null
let lotteryModal, mainPrizeModal, productModal, adminEmailModal
let lotteryForm, mainPrizeForm, productForm, adminEmailForm

let searchProducts, searchCustomers

async function loadData() {
    const response = await request.postRequest(
        '/api/lottery/get_lotteries')

    if (response.status === 200) {
        lotteriesData.value = response.data
    }
}

async function onOpenLottery(index) {
    if (!lotteryForm) lotteryForm = document.querySelector('#id_form_lottery')
    lotteryForm.classList.remove('was-validated')

    lotteryModal = new bootstrap.Modal('#lottery_dialog', {})

    if (index === -1) {
        lottery.main_prizes.length = 0
        lottery.products.length = 0
        lottery.admin_emails.length = 0
        lottery.excluded_users.length = 0
        lottery.test_users.length = 0

        Object.assign(lottery, lotteryObj);
        Object.assign(lottery.static_data, {
            id: 0,
            main_title: null,
            main_content: null,
            full_title: null,
            how_to_win: null,
            how_to_get: null,
            rules_text: null
        });
        lotteryModalTitle.value = 'გათამაშება:ახალი'
        lotteryModal.show()
    } else {

        const response = await request.postRequest(
            '/api/lottery/get_lottery', { id: lotteriesData.value[index].id })

        if (response.status === 200) {

            response.data.start_date = moment(response.data.start_date).format('YYYY-MM-DDTHH:mm');
            if (response.data.end_date !== null) {
                response.data.end_date = moment(response.data.end_date).format('YYYY-MM-DDTHH:mm');
            }

            Object.assign(lottery, response.data);
            lotteryModalTitle.value = 'გათამაშება:' + lotteriesData.value[index].name


            lotteryModal.show()
        }
    }
}

async function saveLottery() {
    if (!lotteryForm.checkValidity()) {
        lotteryForm.classList.add('was-validated')
        return;
    }

    if (lottery.products.length > 0) {
        if (!lottery.product_win_transaction_step || lottery.product_win_transaction_step === 0) {
            alert('მიუთითეთ ბიჯი!')
            return;
        }
    }

    if (lottery.end_date === '') lottery.end_date = null

    const response = await request.postRequest(
        '/api/lottery/save_lottery', lottery)
    if (response.status === 200) {
        lotteryModal.hide()
        await loadData()
    } else {
        alert('შენახვა ვერ მოხერხდა!')
    }
}

/* MAIN PRIZE */

function onMainPrizedImport() {
    mainPrizeFileField.click()
}

function openMainPrize(index) {
    if (!mainPrizeForm) mainPrizeForm = document.querySelector('#id_form_lottery_main_prize')
    mainPrizeForm.classList.remove('was-validated')

    if (index !== -1) {
        Object.assign(mainPrize, lottery.main_prizes[index]);
    } else {
        Object.assign(mainPrize, mainPrizeObj);
    }

    mainPrizeSelectedIndex = index

    mainPrizeModal = new bootstrap.Modal('#lottery_main_prize_dialog', {})
    mainPrizeModal.show()
}

async function deleteMainPrize(index) {
    const yesNo = confirm('წაიშალოს ჩანაწერი?')
    if (yesNo) {
        const _id = lottery.main_prizes[index].id
        if (_id === 0) {
            lottery.main_prizes.splice(index, 1)
        } else {
            const response = await request.postRequest(
                '/api/lottery/delete_lottery_main_prize', { lottery_id: lottery.id, id: _id })
            if (response && response.status === 200) {
                lottery.main_prizes.splice(index, 1)
            }
        }
    }
}

function onMainPrizeFileChange(e) {
    const file = e.target.files || e.dataTransfer.files
    if (!file.length) return

    const reader = new FileReader()
    reader.readAsBinaryString(file[0])

    reader.onload = (e) => {
        const data = e.target.result
        const workbook = XLSX.read(data, {
            type: 'binary'
        })

        const rowObject = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[workbook.SheetNames[0]])

        rowObject.map(c => ({
            id: 0,
            win_transaction_step: c['ტრანზაქცია'].toString(),
            product_name: c['დასახელება'].toString()
        })).forEach(prize => lottery.main_prizes.push(prize))

        mainPrizeFileField.value = null
    }
}

async function addMainPrizeImage(e) {
    if (e.target.files.length > 0) {
        var file = e.target.files[0]
        var reader = new FileReader()
        reader.onloadend = (event) => {

            var index = event.target.result.indexOf(',') + 1
            mainPrize.img = event.target.result.substring(index, event.target.result.length)
        }
        reader.readAsDataURL(file)
    }
}

function saveMainPrize() {
    if (!mainPrizeForm.checkValidity()) {
        mainPrizeForm.classList.add('was-validated')
        return;
    }

    if (mainPrizeSelectedIndex !== -1) {
        lottery.main_prizes[mainPrizeSelectedIndex] = { ...mainPrize }
    } else {
        lottery.main_prizes.push({ ...mainPrize })
    }

    mainPrizeImageField.value = null
    mainPrizeModal.hide()
}

/* END MAIN PRIZE */

/* PRODUCT */

function setProduct(p) {
    product.product_id = p.id
    product.product_name = p.name
    product.product_code = p.code
    product.company_id = p.c_id
    product.company_name = p.c_name
    product.product_img = p.img
}

function openProduct(index) {
    if (!productForm) productForm = document.querySelector('#id_form_lottery_product')
    productForm.classList.remove('was-validated')

    if (index !== -1) {
        Object.assign(product, lottery.products[index]);
    } else {
        Object.assign(product, productObj);
    }

    productSelectedIndex = index

    productModal = new bootstrap.Modal('#lottery_product_dialog', {})
    productModal.show()
}

async function deleteProduct(index) {
    const yesNo = confirm('წაიშალოს ჩანაწერი?')
    if (yesNo) {
        const _id = lottery.products[index].id
        if (_id === 0) {
            lottery.products.splice(index, 1)
        } else {
            const response = await request.postRequest(
                '/api/lottery/delete_lottery_product', { lottery_id: lottery.id, id: _id })
            if (response && response.status === 200) {
                lottery.products.splice(index, 1)
            }
        }
    }
}

function saveProduct() {
    if (!productForm.checkValidity()) {
        productForm.classList.add('was-validated')
        return;
    }

    if (!lottery.product_win_transaction_step || lottery.product_win_transaction_step === 0) {
        alert('მიუთითეთ ბიჯი!')
        return;
    }

    if (product.product_id === null) {
        alert('პროდუქტი არ არის მინიჭებული!')
        return;
    }

    if (productSelectedIndex !== -1) {
        lottery.products[productSelectedIndex] = { ...product }
    } else {
        lottery.products.push({ ...product })
    }

    productModal.hide()
}

/* END PRODUCT */

/* ADMIN EMAILS */

function openAdminEmail(index) {
    if (!adminEmailForm) adminEmailForm = document.querySelector('#id_form_lottery_admin_email')
    adminEmailForm.classList.remove('was-validated')

    if (index !== -1) {
        Object.assign(adminEmail, lottery.admin_emails[index]);
    } else {
        Object.assign(adminEmail, adminEmailObj);
    }

    adminEmailSelectedIndex = index

    adminEmailModal = new bootstrap.Modal('#lottery_admin_email_dialog', {})
    adminEmailModal.show()
}

async function deleteAdminEmail(index) {
    const yesNo = confirm('წაიშალოს ჩანაწერი?')
    if (yesNo) {
        const _id = lottery.admin_emails[index].id
        if (_id === 0) {
            lottery.admin_emails.splice(index, 1)
        } else {
            const response = await request.postRequest(
                '/api/lottery/delete_lottery_admin_email', { lottery_id: lottery.id, id: _id })
            if (response && response.status === 200) {
                lottery.admin_emails.splice(index, 1)
            }
        }
    }
}

function saveAdminEmail() {
    if (!adminEmailForm.checkValidity()) {
        adminEmailForm.classList.add('was-validated')
        return;
    }

    if (adminEmailSelectedIndex !== -1) {
        lottery.admin_emails[adminEmailSelectedIndex] = { ...adminEmail }
    } else {
        lottery.admin_emails.push({ ...adminEmail })
    }

    adminEmailModal.hide()
}

/* END ADMIN EMAILS */

/* EXCLUDED USERS */

async function deleteExcludedUser(index) {
    const yesNo = confirm('წაიშალოს ჩანაწერი?')
    if (yesNo) {
        const _id = lottery.excluded_users[index].id
        if (_id === 0) {
            lottery.excluded_users.splice(index, 1)
        } else {
            const response = await request.postRequest(
                '/api/lottery/delete_lottery_excluded_user', { lottery_id: lottery.id, id: _id })
            if (response && response.status === 200) {
                lottery.excluded_users.splice(index, 1)
            }
        }
    }
}

function setExcludedUser(id, name) {
    let found = lottery.excluded_users.some(u => u.customer_id === id)

    if (!found) {
        lottery.excluded_users.push({
            id: 0,
            customer_id: id,
            customer_name: name
        })
    }
}

/* END EXCLUDED USER */

/* TEST USER */

async function deleteTestUser(index) {
    const yesNo = confirm('წაიშალოს ჩანაწერი?')
    if (yesNo) {
        const _id = lottery.test_users[index].id
        if (_id === 0) {
            lottery.test_users.splice(index, 1)
        } else {
            const response = await request.postRequest(
                '/api/lottery/delete_lottery_test_user', { lottery_id: lottery.id, id: _id })
            if (response && response.status === 200) {
                lottery.test_users.splice(index, 1)
            }
        }
    }
}

function setTestUser(id, name) {
    let found = lottery.test_users.some(u => u.customer_id === id)

    if (!found) {
        lottery.test_users.push({
            id: 0,
            customer_id: id,
            customer_name: name
        })
    }
}

/* END TEST USER */

async function getSearchData() {
    const response = await request.postRequest('/api/lottery/get_search_data', {'id': 0})
    if (response.status === 200) {
        searchProducts = response.data.products
        searchCustomers = response.data.customers
    }

    return []
}

onMounted(async () => {
    await loadData()
    await getSearchData();

    mainPrizeFileField = document.querySelector('#id_file_main_prizes')
    mainPrizeFileField.addEventListener('change', onMainPrizeFileChange)

    mainPrizeImageField = document.querySelector('#id_lottery_main_prize_image')

    const productSearchField = document.querySelector('#id_lottery_product_search')
    new Autocomplete(productSearchField, {
        data: searchProducts.map((p) => ({ 'value': p.id, 'label': p.name })),
        maximumItems: 25,
        threshold: 1,
        // eslint-disable-next-line no-unused-vars
        onSelectItem: ({ label, value }) => {
            productSearchField.value = null
            var product = searchProducts.filter((p) => p.id === parseInt(value))[0]
            setProduct(product)
        }
    })

    const customerSearchExcludedUsersField = document.querySelector('#id_lottery_customer_search_excluded_users')
    new Autocomplete(customerSearchExcludedUsersField, {
        data: searchCustomers,
        maximumItems: 25,
        threshold: 1,
        showSubLabel: true,
        onSelectItem: ({ label, value }) => {
            customerSearchExcludedUsersField.value = null
            setExcludedUser(value, label)
        }
    })

    const customerSearchTestUsersField = document.querySelector('#id_lottery_customer_search_test_users')
    new Autocomplete(customerSearchTestUsersField, {
        data: searchCustomers,
        maximumItems: 5,
        threshold: 1,
        showSubLabel: true,
        onSelectItem: ({ label, value }) => {
            customerSearchTestUsersField.value = null
            setTestUser(value, label)
        }
    })
})
</script>

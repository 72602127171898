<template>
    <div>
        <ToolbarView @search="onSearch" @export="onExport" />
        <GridView class="mt-3" ref="grid" :columns="columns" :data="data" :import-date="true"
            :context-menu-items="contextMenuItems" />
        <div class="modal fade modal-lg" tabindex="-1" data-bs-backdrop="static" id="id_data_dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <form id="id_form_lottery_main_prize" novalidate>
                        <div class="modal-header">
                            <h5 class="modal-title">მონაცეები</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <pre>
                                <code v-html="jsonData"></code>
                            </pre>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import * as bootstrap from 'bootstrap'

import request from '../../http/request'
import ToolbarView from '../../components/ToolbarView.vue'
import GridView from '../../components/GridView.vue'

const store = useStore()

const jsonData = ref()

const columns = [
    {
        headerName: 'შეკვეთის დრო',
        field: 'time',
        cellDataType: 'date',
        cellClass: 'dateType',
        resizable: true,
        sortable: true,
        valueFormatter: (params) => {
            const date = new Date(params.data.time)
            const day = date.getDate().toString().padStart(2, '0')
            const month = (date.getMonth() + 1).toString().padStart(2, '0')
            const year = date.getFullYear()

            const hourNum = date.getHours()

            const hour = (hourNum === 0 ? 0 : hourNum).toString().padStart(2, '0')
            const min = date.getMinutes().toString().padStart(2, '0')
            const sec = date.getSeconds().toString().padStart(2, '0')

            return year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + sec
        }
    },
    { headerName: 'სახელი, გვარი', field: 'name', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
    { headerName: 'ტელეფონი', field: 'phone', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
    { headerName: 'Result', field: 'result', cellDataType: 'text', resizable: true, cellClass: 'stringType', },
]

if (!library)
    var library = {};

library.json = {
    replacer: function (match, pIndent, pKey, pVal, pEnd) {
        var key = '<span class=json-key>';
        var val = '<span class=json-value>';
        var str = '<span class=json-string>';
        var r = pIndent || '';
        if (pKey)
            r = r + key + pKey.replace(/[": ]/g, '') + '</span>: ';
        if (pVal)
            r = r + (pVal[0] == '"' ? str : val) + pVal + '</span>';
        return r + (pEnd || '');
    },
    prettyPrint: function (obj) {
        var jsonLine = /^( *)("[\w]+": )?("[^"]*"|[\w.+-]*)?([,[{])?$/mg;
        return JSON.stringify(obj, null, 3)
            .replace(/&/g, '&amp;').replace(/\\"/g, '&quot;')
            .replace(/</g, '&lt;').replace(/>/g, '&gt;')
            .replace(jsonLine, library.json.replacer);
    }
};

async function onSearch() {
    await loadData()
}

const data = ref([])
const grid = ref(null)

const contextMenuItems = (params) => {
    return [{
        name: 'დეტალები',
        action: () => {
            jsonData.value = library.json.prettyPrint(JSON.parse(params.node.data.result))

            let modal = new bootstrap.Modal('#id_data_dialog', {})
            modal.show()
        }
    }];
}

async function loadData() {
    grid.value.showLoading()
    const response = await request.postRequest(
        '/api/companyportal/get_quickshipper_logs', {
        start_date: store.getters.getPicker.dateFrom,
        end_date: store.getters.getPicker.dateTo
    })

    if (response.status === 200) {
        data.value = response.data
    }

    if (grid.value != null) { grid.value.hideLoading() }
}

function onExport() {
    grid.value.excelExport()
}

onMounted(() => {
    setTimeout(async () => await loadData(), 300)
})
</script>
<style type="text/css">
pre {
    background-color: ghostwhite;
    border: 1px solid silver;
    padding: 10px 20px;
    margin: 20px;
}

.json-key {
    color: brown;
}

.json-value {
    color: navy;
}

.json-string {
    color: olive;
}
</style>
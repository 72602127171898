<template>
    <div>
    <div class="row mt-2">
        <div class="card col-md-2 m-3 d-flex align-items-center">
            <img :src="'data:image/png;base64,' + loyalty.image" class="img-thumbnail rounded"
                style="width: 400px; height: 400px;" alt="static images">
            <div class="my-3 col-md-11">
                <label :for="'formFile' + loyalty.name" class="form-label">{{ loyalty.label }} </label>
                <input accept="image/png" class="form-control" type="file" :id="'formFile' + loyalty.name"
                    @change="addImage($event, 'loyality')">
            </div>
            <div class="col-md-11">
                <button type="button" class="btn btn-outline-primary" @click="saveImage('loyality')">შენახვა</button>
            </div>
        </div>
        <div class="card col-md-2 m-3 d-flex align-items-center">
            <img :src="'data:image/png;base64,' + lottery.image" class="img-thumbnail rounded"
                style="width: 400px; height: 400px;" alt="static images">
            <div class="my-3 col-md-11">
                <label :for="'formFile' + lottery.name" class="form-label">{{ lottery.label }} </label>
                <input accept="image/png" class="form-control" type="file" :id="'formFile' + lottery.name"
                    @change="addImage($event, 'lottery')">
            </div>
            <div class="col-md-11">
                <button type="button" class="btn btn-outline-primary" @click="saveImage('lottery')">შენახვა</button>
            </div>
        </div>
        <div class="card col-md-2 m-3 d-flex align-items-center">
            <img :src="'data:image/png;base64,' + lottery_won.image" class="img-thumbnail rounded"
                style="width: 400px; height: 400px;" alt="static images">
            <div class="my-3 col-md-11">
                <label :for="'formFile' + lottery_won.name" class="form-label">{{ lottery_won.label }} </label>
                <input accept="image/png" class="form-control" type="file" :id="'formFile' + lottery_won.name"
                    @change="addImage($event, 'lottery_won')">
            </div>
            <div class="col-md-11">
                <button type="button" class="btn btn-outline-primary" @click="saveImage('lottery_won')">შენახვა</button>
            </div>
        </div>
        <div class="card col-md-2 p-3 m-3 d-flex align-items-center">
            <img :src="'data:image/png;base64,' + hr_background.image" class="img-thumbnail rounded"
                style="width: 400px; height: 400px;" alt="static images">
            <div class="my-3 col-md-11">
                <label :for="'formFile' + hr_background.name" class="form-label">{{ hr_background.label }} </label>
                <input accept="image/png" class="form-control" type="file" :id="'formFile' + hr_background.name"
                    @change="addImage($event, 'hr_background')">
            </div>
            <div class="col-md-11">
                <button type="button" class="btn btn-outline-primary"
                    @click="saveImage('hr_background')">შენახვა</button>
            </div>
        </div>
        <div class="card col-md-2 p-3 m-3 d-flex align-items-center">
            <img :src="'data:image/png;base64,' + hr_item_ka.image" class="img-thumbnail rounded"
                style="width: 400px; height: 400px;" alt="static images">
            <div class="my-3 col-md-11">
                <label :for="'formFile' + hr_item_ka.name" class="form-label">{{ hr_item_ka.label }} </label>
                <input accept="image/png" class="form-control" type="file" :id="'formFile' + hr_item_ka.name"
                    @change="addImage($event, 'hr_item_ka')">
            </div>
            <div class="col-md-11">
                <button type="button" class="btn btn-outline-primary"
                    @click="saveImage('hr_item_ka')">შენახვა</button>
            </div>
        </div>
        <div class="card col-md-2 p-3 m-3 d-flex align-items-center">
            <img :src="'data:image/png;base64,' + hr_item_en.image" class="img-thumbnail rounded"
                style="width: 400px; height: 400px;" alt="static images">
            <div class="my-3 col-md-11">
                <label :for="'formFile' + hr_item_en.name" class="form-label">{{ hr_item_en.label }} </label>
                <input accept="image/png" class="form-control" type="file" :id="'formFile' + hr_item_en.name"
                    @change="addImage($event, 'hr_item_en')">
            </div>
            <div class="col-md-11">
                <button type="button" class="btn btn-outline-primary"
                    @click="saveImage('hr_item_en')">შენახვა</button>
            </div>
        </div>
        <div class="card col-md-2 p-3 m-3 d-flex align-items-center">
            <img :src="'data:image/png;base64,' + barcode_scan_ka.image" class="img-thumbnail rounded"
                style="width: 400px; height: 400px;" alt="static images">
            <div class="my-3 col-md-11">
                <label :for="'formFile' + barcode_scan_ka.name" class="form-label">{{ barcode_scan_ka.label }} </label>
                <input accept="image/png" class="form-control" type="file" :id="'formFile' + barcode_scan_ka.name"
                    @change="addImage($event, 'barcode_scan_ka')">
            </div>
            <div class="col-md-11">
                <button type="button" class="btn btn-outline-primary"
                    @click="saveImage('barcode_scan_ka')">შენახვა</button>
            </div>
        </div>
        <div class="card col-md-2 p-3 m-3 d-flex align-items-center">
            <img :src="'data:image/png;base64,' + barcode_scan_en.image" class="img-thumbnail rounded"
                style="width: 400px; height: 400px;" alt="static images">
            <div class="my-3 col-md-11">
                <label :for="'formFile' + barcode_scan_en.name" class="form-label">{{ barcode_scan_en.label }} </label>
                <input accept="image/png" class="form-control" type="file" :id="'formFile' + barcode_scan_en.name"
                    @change="addImage($event, 'barcode_scan_en')">
            </div>
            <div class="col-md-11">
                <button type="button" class="btn btn-outline-primary"
                    @click="saveImage('barcode_scan_en')">შენახვა</button>
            </div>
        </div>
    </div>
    <div class="d-flex align-items-end justify-content-end">
        <div class="toast" role="alert" aria-live="assertive" aria-atomic="true" id="success-toast">
            <div class="toast-header">
                <strong class="me-auto">Elco</strong>
                <small>0 წუთის წინ</small>
                <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
            <div class="toast-body">
                სურათი წარმატებით განახლდა
            </div>
        </div>
        <div class="toast" role="alert" aria-live="assertive" aria-atomic="true" id="failure-toast">
            <div class="toast-header">
                <strong class="me-auto">Elco</strong>
                <small>0 წუთის წინ</small>
                <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
            <div class="toast-body">
                შენახვამდე, გთხოვთ ატვირთოთ სურათი
            </div>
        </div>
    </div>
</div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import request from '../../http/request'
import * as bootstrap from 'bootstrap'

let loyalty = ref({ name: 'loyality_card.png', label: 'ლოალობის სურათი', image: '' })
let lottery = ref({ name: 'lottery.png', label: 'ლატარიის სურათი მოგებამდე', image: '' })
let lottery_won = ref({ name: 'lottery_won.png', label: 'ლატარიის სურათი მოგების შემდგომ', image: '' })
let hr_item_ka = ref({ name: 'hr_item_ka.png', label: 'დაიწყე კარიერა ჩვენთან მთავარი (KA)', image: '' })
let hr_item_en = ref({ name: 'hr_item_en.png', label: 'დაიწყე კარიერა ჩვენთან მთავარი (EN)', image: '' })
let hr_background = ref({ name: 'lottery_won.png', label: 'დაიწყე კარიერა ჩვენთან ფონი', image: '' })
let barcode_scan_ka = ref({ name: 'barcode_scan_ka.png', label: 'დაასკანირე და დააგროვე მთავარი (KA)', image: '' })
let barcode_scan_en = ref({ name: 'barcode_scan_en.png', label: 'დაასკანირე და დააგროვე მთავარი (EN)', image: '' })


async function loadData() {
    const response = await request.postRequest(
        '/api/companyportal/get_static_images',)
    if (response.status === 200) {
        let loyaltyImage = response.data.find(img => img.name === 'loyality_card.png');
        let lotteryImage = response.data.find(img => img.name === 'lottery.png');
        let lotteryWonImage = response.data.find(img => img.name === 'lottery_won.png');
        let hrBackgroundImage = response.data.find(img => img.name === 'hr_background.png');
        let hrItemKaImage = response.data.find(img => img.name === 'hr_item_ka.png');
        let hrItemEnImage = response.data.find(img => img.name === 'hr_item_en.png');
        let qrScanKaImage = response.data.find(img => img.name === 'barcode_scan_ka.png');
        let qrScanEnImage = response.data.find(img => img.name === 'barcode_scan_en.png');

        loyalty.value['image'] = loyaltyImage?.image;
        lottery.value['image'] = lotteryImage?.image;
        lottery_won.value['image'] = lotteryWonImage?.image;
        hr_background.value['image'] = hrBackgroundImage?.image;
        hr_item_ka.value['image'] = hrItemKaImage?.image;
        hr_item_en.value['image'] = hrItemEnImage?.image;
        barcode_scan_ka.value['image'] = qrScanKaImage?.image;
        barcode_scan_en.value['image'] = qrScanEnImage?.image;
    }
}



async function addImage(e, name) {
    if (e.target.files.length > 0) {
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.onloadend = (event) => {

            let imageIndex = event.target.result.indexOf(',') + 1;
            let image = event.target.result.substring(imageIndex, event.target.result.length)

            switch(name) {
                case 'loyality':
                    loyalty.value.image = image
                    break
                case 'lottery':
                    lottery.value.image = image
                    break
                case 'lottery_won':
                    lottery_won.value.image = image
                    break
                case 'hr_background':
                    hr_background.value.image = image
                    break
                case 'hr_item_ka':
                    hr_item_ka.value.image = image
                    break
                case 'hr_item_en':
                    hr_item_en.value.image = image
                    break
                case 'barcode_scan_ka':
                    barcode_scan_ka.value.image = image
                    break
                case 'barcode_scan_en':
                    barcode_scan_en.value.image = image
                    break
            }
        };
        reader.readAsDataURL(file);
    }
}


async function saveImage(name) {
    let imageObject;
    switch (name) {
        case 'loyality':
            imageObject = loyalty;
            break;
        case 'lottery':
            imageObject = lottery;
            break;
        case 'lottery_won':
            imageObject = lottery_won;
            break;
        case 'hr_background':
            imageObject = hr_background;
            break;
        case 'hr_item_ka':
            imageObject = hr_item_ka;
            break;
        case 'hr_item_en':
            imageObject = hr_item_en;
            break;
        case 'barcode_scan_ka':
            imageObject = barcode_scan_ka;
            break;
        case 'barcode_scan_en':
            imageObject = barcode_scan_en;
            break;
        default:
            imageObject = loyalty;
            break;
    }
    if (imageObject.value['image'] !== null && imageObject.value['image'] !== "" && imageObject.value['image'] !== undefined) {
        await request.postRequest('/api/companyportal/save_or_update_static_image', { name: imageObject.value['name'], folder_name: "companies", image: imageObject.value['image'] });
        let toast = new bootstrap.Toast(document.getElementById('success-toast'));
        toast.show();
        setTimeout(() => {
            toast.hide();
        }, 2000);
    } else {
        let toast = new bootstrap.Toast(document.getElementById('failure-toast'));
        toast.show();
        setTimeout(() => {
            toast.hide();
        }, 2000);
    }
}
onMounted(async () => {
    await loadData()
})

</script>
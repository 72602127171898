<template>
    <div>
        <GridView class="mt-3" ref="grid" :columns="columns" :data="productsData" :import-date="false"
            :context-menu-items="contextMenuItems" />

        <div class="modal fade modal-lg" tabindex="-1" data-bs-backdrop="static" id="times_dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">დროები</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="d-flex flex-row justify-content-between shadow-sm p-2 mt-1 bg-body-tertiary rounded"
                            v-if="times.length < 7">
                            <button type="button" class="btn btn-primary btn-sm" @click="addTime">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-plus-lg" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd"
                                        d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                </svg>
                                დამატება
                            </button>
                        </div>
                        <table class="table table-striped table-sm">
                            <thead>
                                <tr>
                                    <th scope="col">დღე</th>
                                    <th scope="col" class="text-center">24/7</th>
                                    <th scope="col" class="text-center" style="width: 100px;">დაწყება</th>
                                    <th scope="col" class="text-center" style="width: 100px;">დასრულება</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(time, index) in times" :key="index" style="cursor: pointer;">
                                    <td>
                                        <select class="form-control form-control-sm" v-model="time.weekday" required>
                                            <option v-for="(weekDay, index) in weekDays" :value="index" :key="index">{{
            weekDay }}
                                            </option>
                                        </select>
                                    </td>
                                    <td class="text-center">
                                        <input class="form-check-input" type="checkbox" role="switch"
                                            v-model="time.is_alltime">
                                    </td>
                                    <td class="text-center">
                                        <input type="time" v-model="time.start_time" :readonly="time.is_alltime"
                                            class="form-control form-control-sm" required />
                                    </td>
                                    <td class="text-center">
                                        <input type="time" v-model="time.end_time" :readonly="time.is_alltime"
                                            class="form-control form-control-sm" required />
                                    </td>
                                    <td class="text-center"><button type="button" class="btn btn-danger btn-sm"
                                            @click.stop="removeTime(index)">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                <path
                                                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                                <path
                                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                            </svg>
                                        </button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-success" @click="saveTimes">შენახვა</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue'
import request from '../../http/request'
import GridView from '../../components/GridView.vue'
import * as bootstrap from 'bootstrap'

import { useStore } from 'vuex'

const store = useStore()

let weekDays = ref(['კვირა', 'ორშაბათი', 'სამშაბათი', 'ოთხშაბათი', 'ხუთშაბათი', 'პარასკევი', 'შაბათი'])

const productsData = ref([])
let times = reactive([])

let _id = 0
let timesModal

const columns = [
    { headerName: 'დასახელება', field: 'name', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
    { headerName: 'დასახელება Eng', field: 'code', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
    { headerName: 'კომპანია', field: 'group_name', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
    { headerName: 'დამალული', field: 'hidden', cellDataType: 'boolean', cellClass: 'booleanType', resizable: true, sortable: true },
]

async function loadData() {
    const response = await request.postRequest(
        '/api/companyportal/get_products_group', { company_id: store.getters.getCompanyId })

    if (response.status === 200) {
        productsData.value = response.data
    }
}

const contextMenuItems = (params) => {
    let menu = [];

    _id = params.node.data.id

    if (params.node.data.hidden) {
        menu.push({
            name: 'გამოჩენა',
            action: async () => await hiddenShow(_id, false)
        });
    } else {
        menu.push({
            name: 'დამალვა',
            action: async () => await hiddenShow(_id, true)
        });
    }

    menu.push({
        name: 'საათობრივი დამალვა/გამოჩება',
        action: async () => await openHoursModal(_id, false)
    });

    return menu;
}

async function hiddenShow(id, sign) {
    if (confirm('ნამდვილად გსურთ ჯგუფის ' + (!sign ? 'გამოჩენა' : 'დამალვა'))) {
        const response = await request.postRequest(
            '/api/companyportal/product_group_hidden_show', { id, is_active: sign })

        if (response.status === 200) {
            await loadData()
        }
    }
}

async function openHoursModal(id) {
    timesModal = new bootstrap.Modal('#times_dialog', {})

    const response = await request.postRequest('/api/companyportal/get_product_availability_times', { id: id })
    if (response.status === 200) {

        Object.assign(times, response.data);
        timesModal.show()
    }
}

function addTime() {
    if (times.length < 8) {
        times.push({
            'weekday': 0,
            'is_alltime': true,
            'start_time': null,
            'end_time': null,
            'group_id': _id,
        })
    }
}

function removeTime(index) {
    const yesNo = confirm('წაიშალოს ჩანაწერი?')
    if (yesNo) {
        times.splice(index, 1)
    }
}

async function saveTimes() {
    const response = await request.postRequest(
        '/api/companyportal/set_product_availability_times', {group_id: _id, times: times})
    if (response.data > 0) {
        timesModal.hide()
    } else {
        alert(response.data.message)
    }
}

onMounted(async () => {
    await loadData()
})
</script>
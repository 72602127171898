import Router from '../router/index'
import Store from '../store/index'

export default {
  getHeaderToken () {
    const _header = { Validation: 'elcoapp_fina' }

    const _token = Store.getters.getToken

    if (_token !== null) {
      _header.Authorization = 'Bearer ' + _token
    }

    return _header
  },

  redirectToLogin () {
    Store.dispatch('removeCurrentUser')
    Router.push({ name: 'login' }).catch(() => { })
  }
}

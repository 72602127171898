<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex flex-row justify-content-start shadow-sm p-2 bg-body-tertiary rounded">
                    <button type="button" class="btn btn-success" @click="onOpenLoyality(-1)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-plus-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path
                                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        ახალი ლოიალობა
                    </button>
                    <button class="mx-2 btn btn-primary" @click="moveRowUp"
                        :disabled="selectedRow === null || selectedRow === 0">
                        <svg fill="white" height="15px" width="15px" version="1.1" id="Layer_1"
                            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 330 330" xml:space="preserve">
                            <path id="XMLID_224_"
                                d="M325.606,229.393l-150.004-150C172.79,76.58,168.974,75,164.996,75c-3.979,0-7.794,1.581-10.607,4.394
                                l-149.996,150c-5.858,5.858-5.858,15.355,0,21.213c5.857,5.857,15.355,5.858,21.213,0l139.39-139.393l139.397,139.393
                                C307.322,253.536,311.161,255,315,255c3.839,0,7.678-1.464,10.607-4.394C331.464,244.748,331.464,235.251,325.606,229.393z" />
                        </svg>
                    </button>
                    <button class="btn btn-primary" @click="moveRowDown"
                        :disabled="selectedRow === null || selectedRow === loyalitiesData.length - 1">
                        <svg fill="white" height="15px" width="15px" version="1.1" id="Layer_1"
                            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 330 330" xml:space="preserve">
                            <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
                            c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
                            s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
                        </svg>
                    </button>
                    <select class="form-select form-select-sm mx-2" style="width:200px;" v-model="showMode" @change="loadData">
                        <option :value="1">მიმდინარე</option>
                        <option :value="2">დამალული</option>
                        <option :value="3">ყველა</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-12">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th></th>
                            <th scope="col">დასახელება</th>
                            <th scope="col">დაწყების თარიღი</th>
                            <th scope="col">დასრულების თარიღი</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(loyality, index) in loyalitiesData" :key="loyality.id"
                            @dblclick="onOpenLoyality(index)" style="cursor: pointer;" @click="onRowClick(index)">
                            <td>{{ loyality.order_by }}</td>
                            <td :style="{ 'background-color': getRowColor(index) }">{{ loyality.name }}</td>
                            <td :style="{ 'background-color': getRowColor(index) }">{{
                                moment(loyality.start_date).format('YYYY-MM-DD') }}</td>
                            <td :style="{ 'background-color': getRowColor(index) }">
                                {{ moment(loyality.end_date).format('YYYY-MM-DD') }}
                            </td>
                        </tr>
                        <tr v-if="loyalitiesData.length === 0">
                            <td colspan="3" class="text-center">
                                ჩანაწერები ვერ მოიძებნა
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="modal fade modal-lg" tabindex="-1" data-bs-backdrop="static" id="loyality_dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <form id="id_form_loyality" novalidate>
                        <div class="modal-header">
                            <h5 class="modal-title">{{ modalTitle }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <ul class="nav nav-tabs">
                                <li class="nav-item">
                                    <button class="nav-link active" id="deal-tab" data-bs-toggle="tab"
                                        data-bs-target="#deal-tab-pane" type="button" role="tab"
                                        aria-controls="deal-tab-pane" aria-selected="true">აქცია</button>
                                </li>
                                <li class="nav-item" v-if="loyality.type == 2 ||
                        loyality.type == 4
                        ">
                                    <button class="nav-link" id="gifts-tab" data-bs-toggle="tab"
                                        data-bs-target="#products1-tab-pane" type="button" role="tab"
                                        aria-controls="products1-tab-pane" aria-selected="false">
                                        <template v-if="loyality.type == 2">
                                            ფასდაკლებული პროდუქტები
                                        </template>
                                        <template v-else>
                                            პროდუქტები
                                        </template>
                                    </button>
                                </li>
                                <li class="nav-item" v-if="loyality.type === 5">
                                    <button class="nav-link" id="products-tab" data-bs-toggle="tab"
                                        data-bs-target="#products-tab-pane" type="button" role="tab"
                                        aria-controls="products-tab-pane" aria-selected="false">
                                        პროდუქტები</button>
                                </li>

                                <li class="nav-item" v-if="loyality.type === 5">
                                    <button class="nav-link" id="gifts-tab" data-bs-toggle="tab"
                                        data-bs-target="#gifts-tab-pane" type="button" role="tab"
                                        aria-controls="gifts-tab-pane" aria-selected="false">საჩუქრები</button>
                                </li>

                                <li class="nav-item">
                                    <button class="nav-link" id="branches-tab" data-bs-toggle="tab"
                                        data-bs-target="#branches-tab-pane" type="button" role="tab"
                                        aria-controls="branches-tab-pane" aria-selected="false">ფილიალები</button>
                                </li>
                                <li class="nav-item">
                                    <button class="nav-link" id="validdays-tab" data-bs-toggle="tab"
                                        data-bs-target="#validdays-tab-pane" type="button" role="tab"
                                        aria-controls="validdays-tab-pane" aria-selected="false">მოქმედების დრო</button>
                                </li>
                            </ul>
                            <div class="tab-content">
                                <div class="tab-pane fade show active" id="deal-tab-pane" role="tabpanel"
                                    aria-labelledby="deal-tab" tabindex="0">
                                    <div class="form-floating mb-3 mt-3">
                                        <input type="text" class="form-control form-control-sm" id="id_name"
                                            placeholder="დასახელება" v-model="loyality.name" required />
                                        <label for="id_name">დასახელება</label>
                                    </div>
                                    <div class="form-floating mb-3 mt-3">
                                        <input type="text" class="form-control form-control-sm" id="id_name_en"
                                            placeholder="English Name" v-model="loyality.name_en" required />
                                        <label for="id_name_en">English name</label>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col">
                                            <div class="form-floating">
                                                <input type="date" class="form-control form-control-sm"
                                                    id="id_start_date" placeholder="დაწყების დრო"
                                                    v-model="loyality.start_date" required />
                                                <label for="id_start_date">დაწყების დრო</label>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-floating">
                                                <input type="date" class="form-control form-control-sm" id="id_end_date"
                                                    placeholder="დასრულების დრო" v-model="loyality.end_date" required />
                                                <label for="id_end_date">დასრულების დრო</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <select class="form-control form-control-sm" id="id_type"
                                            v-model="loyality.type" required>
                                            <option v-for=" dealType  in  dealTypes " :value="dealType.id"
                                                :key="dealType.id">{{
                                                dealType.name }}</option>
                                        </select>
                                        <label for="id_type">აქციის ტიპი</label>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input type="text" class="form-control form-control-sm" id="id_short_desc"
                                            placeholder="მოკლე აღწერა" v-model="loyality.short_desc" required />
                                        <label for="id_short_desc">მოკლე აღწერა</label>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input type="text" class="form-control form-control-sm" id="id_short_desc_en"
                                            placeholder="Short Description in English" v-model="loyality.short_desc_en"
                                            required />
                                        <label for="id_short_desc_en">Short Description in English</label>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input type="text" class="form-control form-control-sm" id="id_long_desc"
                                            placeholder="აღწერა" v-model="loyality.long_desc" required />
                                        <label for="id_long_desc">აღწერა</label>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input type="text" class="form-control form-control-sm" id="id_long_desc_en"
                                            placeholder="Description in English" v-model="loyality.long_desc_en"
                                            required />
                                        <label for="id_long_desc_en">Description in English</label>
                                    </div>
                                    <div class="form-floating mb-3" v-if="loyality.type === 4">
                                        <input type="number" class="form-control form-control-sm" id="id_point"
                                            placeholder="ქულა" v-model="loyality.discount_percent" required />
                                        <label for="id_point">ქულა</label>
                                    </div>
                                    <div class="form-floating mb-3" v-if="loyality.type === 2">
                                        <input type="number" class="form-control form-control-sm"
                                            id="id_discount_percent" placeholder="ფასდაკლების პროცენტი"
                                            v-model="loyality.discount_percent" required />
                                        <label for="id_discount_percent">ფასდაკლების პროცენტი</label>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input type="file" accept="image/jpeg" class="form-control form-control-sm"
                                            placeholder="აირჩიეთ სურათი" id="id_image" @change="addImage" required />
                                        <label for="id_image">სურათი</label>
                                    </div>
                                    <img v-if="loyality.img" style="width: 200px; height: 200px;"
                                        :src="'data:image/jpeg;base64,' + loyality.img" class="img-thumbnail" alt="img">

                                    <div class="form-check form-switch mb-3" v-if="loyality.id > 0">
                                        <input class="form-check-input" type="checkbox" role="switch"
                                            id="id_lottery_hidden" v-model="loyality.hidden">
                                        <label class="form-check-label" for="id_lottery_hidden">დამალული</label>
                                    </div>

                                    <div class="form-check form-switch mb-3" v-if="loyality.id > 0">
                                        <input class="form-check-input" type="checkbox" role="switch"
                                            id="id_lottery_hide_on_delivery" v-model="loyality.hide_on_delivery">
                                        <label class="form-check-label" for="id_lottery_hide_on_delivery">დამალვა მიწოდებაში</label>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="products1-tab-pane" role="tabpanel"
                                    aria-labelledby="products1-tab" tabindex="1" v-if="loyality.type == 2 ||
                        loyality.type == 4
                        ">

                                    <div
                                        class="d-flex flex-row justify-content-between shadow-sm p-2 mt-1 bg-body-tertiary rounded">

                                        <div class="mb-3">
                                            <input type="text" class="form-control form-control-sm"
                                                id="id_products_search" placeholder="ძებნა" autocomplete="off" />
                                        </div>
                                    </div>
                                    <div style="max-height: 350px; overflow-y: auto;">
                                        <table class="table table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th scope="col">პროდუქტი</th>
                                                    <th scope="col">კომპანია</th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(product, index) in loyality.deal_products" :key="product.id"
                                                    style="cursor: pointer;">
                                                    <td>
                                                        <div>{{ product.product_name }}</div>
                                                        <small>{{ product.code }}</small>
                                                    </td>
                                                    <td>
                                                        {{ product.company_name }}
                                                    </td>
                                                    <td class="text-center"><button type="button"
                                                            class="btn btn-danger btn-sm"
                                                            @click.stop="removeDealProduct(index)">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                height="16" fill="currentColor" class="bi bi-trash"
                                                                viewBox="0 0 16 16">
                                                                <path
                                                                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                                                <path
                                                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                                            </svg>
                                                        </button></td>
                                                </tr>
                                                <tr v-if="loyality.deal_products.length === 0">
                                                    <td colspan="2" class="text-center">
                                                        ჩანაწერები ვერ მოიძებნა
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                <div class="tab-pane fade" id="products-tab-pane" role="tabpanel"
                                    aria-labelledby="products-tab" tabindex="2" v-if="loyality.type === 5">

                                    <div
                                        class="d-flex flex-row justify-content-between shadow-sm p-2 mt-1 bg-body-tertiary rounded">

                                        <div class="mb-3">
                                            <input type="text" class="form-control form-control-sm"
                                                id="id_products_search" placeholder="ძებნა" autocomplete="off" />
                                        </div>
                                    </div>
                                    <div style="max-height: 350px; overflow-y: auto;">
                                        <table class="table table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th scope="col">პროდუქტი</th>
                                                    <th scope="col">ჯგუფი</th>
                                                    <th scope="col">რაოდენობა</th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(product, index) in loyality.deal_products" :key="product.id"
                                                    style="cursor: pointer;">
                                                    <td>{{ product.product_name }}</td>
                                                    <td>{{ product.group_name }}</td>
                                                    <td>{{ product.cnt }}</td>
                                                    <td class="text-center"><button type="button"
                                                            class="btn btn-danger btn-sm"
                                                            @click.stop="removeDealProduct(index)">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                height="16" fill="currentColor" class="bi bi-trash"
                                                                viewBox="0 0 16 16">
                                                                <path
                                                                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                                                <path
                                                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                                            </svg>
                                                        </button></td>
                                                </tr>
                                                <tr v-if="loyality.deal_products.length === 0">
                                                    <td colspan="4" class="text-center">
                                                        ჩანაწერები ვერ მოიძებნა
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                <div class="tab-pane fade" id="gifts-tab-pane" role="tabpanel"
                                    aria-labelledby="gifts-tab" tabindex="3" v-if="loyality.type === 5">

                                    <div
                                        class="d-flex flex-row justify-content-between shadow-sm p-2 mt-1 bg-body-tertiary rounded">

                                        <div class="mb-3">
                                            <input type="text" class="form-control form-control-sm" id="id_gifts_search"
                                                placeholder="ძებნა" autocomplete="off" />
                                        </div>
                                    </div>
                                    <div style="max-height: 350px; overflow-y: auto;">
                                        <table class="table table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th scope="col">პროდუქტი</th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(product, index) in loyality.deal_gift_products"
                                                    :key="product.id" style="cursor: pointer;">
                                                    <td>{{ product.product_name }}</td>
                                                    <td class="text-center"><button type="button"
                                                            class="btn btn-danger btn-sm"
                                                            @click.stop="removeDealGiftProduct(index)">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                height="16" fill="currentColor" class="bi bi-trash"
                                                                viewBox="0 0 16 16">
                                                                <path
                                                                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                                                <path
                                                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                                            </svg>
                                                        </button></td>
                                                </tr>
                                                <tr v-if="loyality.deal_products.length === 0">
                                                    <td colspan="2" class="text-center">
                                                        ჩანაწერები ვერ მოიძებნა
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="branches-tab-pane" role="tabpanel"
                                    aria-labelledby="branches-tab" tabindex="4">

                                    <div
                                        class="d-flex flex-row justify-content-between shadow-sm p-2 mt-1 bg-body-tertiary rounded">

                                        <div class="mb-3">
                                            <input type="text" class="form-control form-control-sm"
                                                id="id_branches_search" placeholder="ძებნა" autocomplete="off" />
                                        </div>

                                        <div class="mb-3">
                                            <button type="button" class="btn btn-primary btn-sm" @click="fillAllBranches">ყველას ჩაყრა</button>
                                        </div>

                                        
                                    </div>
                                    <div style="max-height: 350px; overflow-y: auto;">
                                        <table class="table table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th scope="col">დასახელება</th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(branch, index) in loyality.branches" :key="branch.id"
                                                    style="cursor: pointer;">
                                                    <td>{{ branch.name }}</td>
                                                    <td class="text-center"><button type="button"
                                                            class="btn btn-danger btn-sm"
                                                            @click.stop="removeBranch(index)">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                height="16" fill="currentColor" class="bi bi-trash"
                                                                viewBox="0 0 16 16">
                                                                <path
                                                                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                                                <path
                                                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                                            </svg>
                                                        </button></td>
                                                </tr>
                                                <tr v-if="loyality.branches.length === 0">
                                                    <td colspan="4" class="text-center">
                                                        ჩანაწერები ვერ მოიძებნა
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                <div class="tab-pane fade" id="validdays-tab-pane" role="tabpanel"
                                    aria-labelledby="validdays-tab" tabindex="1">
                                    <div class="d-flex flex-row justify-content-between shadow-sm p-2 mt-1 bg-body-tertiary rounded"
                                        v-if="loyality.valid_days.length < 7">
                                        <button type="button" class="btn btn-primary btn-sm" @click="addValidDays">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd"
                                                    d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                            </svg>
                                            დამატება
                                        </button>
                                    </div>
                                    <table class="table table-striped table-sm">
                                        <thead>
                                            <tr>
                                                <th scope="col">დღე</th>
                                                <th scope="col" class="text-center">24/7</th>
                                                <th scope="col" class="text-center" style="width: 100px;">დაწყება</th>
                                                <th scope="col" class="text-center" style="width: 100px;">დასრულება</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(validday, index) in loyality.valid_days" :key="index"
                                                style="cursor: pointer;">
                                                <td>
                                                    <select class="form-control form-control-sm"
                                                        v-model="validday.weekday" required>
                                                        <option v-for="(weekDay, index) in weekDays" :value="index"
                                                            :key="index">{{ weekDay }}
                                                        </option>
                                                    </select>
                                                </td>
                                                <td class="text-center">
                                                    <input class="form-check-input" type="checkbox" role="switch"
                                                        v-model="validday.is_alltime">
                                                </td>
                                                <td class="text-center">
                                                    <input type="time" v-model="validday.start_time"
                                                        :readonly="validday.is_alltime"
                                                        class="form-control form-control-sm" required />
                                                </td>
                                                <td class="text-center">
                                                    <input type="time" v-model="validday.end_time"
                                                        :readonly="validday.is_alltime"
                                                        class="form-control form-control-sm" required />
                                                </td>
                                                <td class="text-center"><button type="button"
                                                        class="btn btn-danger btn-sm" @click.stop="removeDay(index)">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                            fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                            <path
                                                                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                                            <path
                                                                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                                        </svg>
                                                    </button></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-success" @click="saveDeal">შენახვა</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, reactive, watch, computed } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import * as bootstrap from 'bootstrap'

import request from '../../http/request'
import { Autocomplete } from '../../components/autocomplete'

const store = useStore()

let modalTitle = ref()
const loyalitiesData = ref([])
const selectedRow = ref(null)
const showMode = ref(1)

let dealTypes = ref([
    { id: 2, name: 'პროდუქტების ფასდაკლება' },
    { id: 4, name: 'იყიდე და მიიღე დამატებით ქულა' },
    { id: 5, name: '2+1' }
])


let weekDays = ref(['კვირა', 'ორშაბათი', 'სამშაბათი', 'ოთხშაბათი', 'ხუთშაბათი', 'პარასკევი', 'შაბათი'])

let searchProducts, searchBranches
let loyalityForm, loyalityModal

const loyalityObj = {
    id: 0,
    company_id: store.getters.getCompanyId,
    name: null,
    name_ru: '',
    name_en: '',
    start_date: null,
    end_date: null,
    type: null,
    short_desc: null,
    short_desc_ru: '',
    short_desc_en: '',
    long_desc: null,
    long_desc_ru: '',
    long_desc_en: '',
    discount_percent: 0,
    end_age: 1000,
    active_day_repeat: 0,
    sex: 2,
    order_minimum_amount: 0,
    img: null,
    hidden: false,
    hide_on_delivery: false,
    deal_products: [],
    deal_gift_products: [],
    branches: [],
    valid_days: [],
}

const loyality = reactive({ ...loyalityObj })

watch(() => loyality.type, () => {
    makeAutocomplete();
})

async function onOpenLoyality(index) {
    if (!loyalityForm) loyalityForm = document.querySelector('#id_form_loyality')
    loyalityForm.classList.remove('was-validated')

    loyalityModal = new bootstrap.Modal('#loyality_dialog', {})

    if (index === -1) {

        Object.assign(loyality, loyalityObj);
        modalTitle.value = 'ლოიალობა:ახალი'
        loyalityModal.show()

        makeAutocomplete()
    } else {
        const response = await request.postRequest(
            '/api/companyportal/get_deals_by_id', { id: loyalitiesData.value[index].id })

        if (response.status === 200) {

            response.data.start_date = moment(response.data.start_date).format('YYYY-MM-DD')
            response.data.end_date = moment(response.data.end_date).format('YYYY-MM-DD')

            Object.assign(loyality, response.data);
            modalTitle.value = 'ლოიალობა:' + loyalitiesData.value[index].name

            loyalityModal.show()

            makeAutocomplete()
        }
    }

    const tabTrigger = new bootstrap.Tab(document.querySelector('#deal-tab'))
    tabTrigger.show()
}

function removeDealProduct(index) {
    const yesNo = confirm('წაიშალოს ჩანაწერი?')
    if (yesNo) {
        loyality.deal_products.splice(index, 1)
    }
}

function removeDealGiftProduct(index) {
    const yesNo = confirm('წაიშალოს ჩანაწერი?')
    if (yesNo) {
        loyality.deal_gift_products.splice(index, 1)
    }
}

function removeBranch(index) {
    const yesNo = confirm('წაიშალოს ჩანაწერი?')
    if (yesNo) {
        loyality.branches.splice(index, 1)
    }
}

function fillAllBranches() {

    if(loyality.branches.length === 0) {
        searchBranches.forEach((b) => loyality.branches.push({
                        "id": 0,
                        "deal_id": loyality.id,
                        "branch_id": parseInt(b.value),
                        "name": b.label,
                    })) 
    }
}

async function loadData() {
    const response = await request.postRequest(
        '/api/companyportal/get_deals', {
        company_id: store.getters.getCompanyId,
        mode: showMode.value
    })

    if (response.status === 200) {
        loyalitiesData.value = response.data
    }
}

async function saveDeal() {
    const response = await request.postRequest(
        '/api/companyportal/add_or_update_deal', loyality)
    if (response.data.id > 0) {
        await loadData()
        loyalityModal.hide()
    } else {
        alert(response.data.message)
    }
}

async function addImage(e) {
    if (e.target.files.length > 0) {
        var file = e.target.files[0]
        var reader = new FileReader()
        reader.onloadend = (event) => {

            var index = event.target.result.indexOf(',') + 1
            loyality.img = event.target.result.substring(index, event.target.result.length)
        }
        reader.readAsDataURL(file)
    }
}

async function getSearchData() {
    const response = await request.postRequest('/api/companyportal/get_loyality_search_data', { id: store.getters.getCompanyId })
    if (response.status === 200) {
        searchProducts = response.data.products
        searchBranches = response.data.branches
    }
}

function addValidDays() {
    if (loyality.valid_days.length < 8) {
        loyality.valid_days.push({
            'weekday': 0,
            'is_alltime': true,
            'start_time': null,
            'end_time': null
        })
    }
}

function removeDay(index) {
    const yesNo = confirm('წაიშალოს ჩანაწერი?')
    if (yesNo) {
        loyality.valid_days.splice(index, 1)
    }
}

function makeAutocomplete() {
    setTimeout(() => {
        if ((loyality.type === 2 || loyality.type === 4 || loyality.type === 5) && searchProducts) {
            const productSearchField = document.querySelector('#id_products_search')
            new Autocomplete(productSearchField, {
                data: searchProducts.map((p) => ({ 'value': p.id, 'label': p.name, 'subLabel': p.code })),
                maximumItems: 10,
                threshold: 1,
                showSubLabel: true,
                onSelectItem: ({ label, value }) => {
                    productSearchField.value = null

                    loyality.deal_products.push({
                        "id": 0,
                        "product_id": parseInt(value),
                        "product_name": label,
                        "price": 0,
                        "deal_id": loyality.id,
                        "company_name": null,
                        "code": null,
                        "cnt": 1
                    });
                }
            })
        }

        if (loyality.type === 5 && searchProducts) {
            const productGiftsField = document.querySelector('#id_gifts_search')
            new Autocomplete(productGiftsField, {
                data: searchProducts.map((p) => ({ 'value': p.id, 'label': p.name, 'subLabel': p.code })),
                maximumItems: 10,
                threshold: 1,
                showSubLabel: true,
                onSelectItem: ({ label, value }) => {
                    productGiftsField.value = null

                    loyality.deal_gift_products.push({
                        "id": 0,
                        "deal_id": loyality.id,
                        "product_id": parseInt(value),
                        "company_name": label,
                        "code": null,
                        "price": 0
                    });
                }
            })
        }

        if (searchBranches) {
            const branchesSearchField = document.querySelector('#id_branches_search')
            new Autocomplete(branchesSearchField, {
                data: searchBranches,
                maximumItems: 10,
                threshold: 1,
                showSubLabel: true,
                onSelectItem: ({ label, value }) => {
                    branchesSearchField.value = null

                    loyality.branches.push({
                        "id": 0,
                        "deal_id": loyality.id,
                        "branch_id": parseInt(value),
                        "name": label,
                    });
                }
            })
        }

    }, 500);

}

const onRowClick = (index) => {
    selectedRow.value = index;
};


const moveRowUp = () => {
    if (selectedRow.value !== null && selectedRow.value > 0) {
        const selectedLoyalty = loyalitiesData.value[selectedRow.value];
        const aboveLoyalty = loyalitiesData.value[selectedRow.value - 1];
        const temp = selectedLoyalty.order_by;
        selectedLoyalty.order_by = aboveLoyalty.order_by;
        aboveLoyalty.order_by = temp;
        selectedRow.value--;
        updateDealOrdering([selectedLoyalty, aboveLoyalty])
    }
};

const moveRowDown = () => {
    if (selectedRow.value !== null && selectedRow.value < loyalitiesData.value.length - 1) {
        const selectedLoyalty = loyalitiesData.value[selectedRow.value];
        const belowLoyalty = loyalitiesData.value[selectedRow.value + 1];
        const temp = selectedLoyalty.order_by;
        selectedLoyalty.order_by = belowLoyalty.order_by;
        belowLoyalty.order_by = temp;
        selectedRow.value++;
        updateDealOrdering([selectedLoyalty, belowLoyalty])
    }
};



const getRowColor = (index) => {
    return selectedRow.value === index ? '#0d6efd' : '';
}

async function updateDealOrdering(data) {
    const response = await request.postRequest(
        '/api/companyportal/update_deals_ordering', data)
    if (response.data !== "") {
        await loadData()
    } else {
        alert("სორტირებისას დაფიქსირდა შეცდომა");
    }
}


onMounted(async () => {
    await loadData()
    await getSearchData();
})
</script>
<template>
    <div>
        <ToolbarView @search="onSearch" @export="onExport" />
        <GridView class="mt-3" ref="grid" :columns="columns" :data="data" :import-date="true" :context-menu-items="contextMenuItems" />
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'

import request from '../../http/request'
import ToolbarView from '../../components/ToolbarView.vue'
import GridView from '../../components/GridView.vue'

const store = useStore()

const columns = [
    { headerName: 'სახელი, გვარი', field: 'customer_name', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
    { headerName: 'ტელეფონი', field: 'customer_phone', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
    { headerName: 'ელ-ფოსტა', field: 'customer_email', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
    { headerName: 'გათამაშება', field: 'lottery_name', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
    {
        headerName: 'მოგების დრო',
        field: 'won_date',
        cellDataType: 'dateString',
        cellClass: 'dateType',
        resizable: true,
        sortable: true,
        valueGetter: params => params.data.won_date.split('T')[0]
    },
    {
        headerName: 'განაღდების დრო',
        field: 'prize_get_date',
        cellDataType: 'dateString',
        cellClass: 'dateType',
        resizable: true,
        sortable: true,
        valueGetter: params => params.data.prize_get_date !== null ? params.data.prize_get_date.split('T')[0] : ''
    },
    { headerName: 'მთავარი პრიზი', field: 'is_main_prize', cellDataType: 'boolean', cellClass: 'booleanType', resizable: true, sortable: true },
    { headerName: 'მოგებული პრიზი', field: 'product_name', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
    { headerName: 'ტრანზ.', field: 'won_transaction_num', cellDataType: 'number', cellClass: 'numberType', resizable: true, sortable: true, type: 'rightAligned' },
    { headerName: 'შეკვეთის ნომერი', field: 'order_id', cellDataType: 'text', resizable: true },
    { headerName: 'მოგების რესტორანი', field: 'won_branch', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
    { headerName: 'განაღდების რესტორანი', field: 'prize_get_branch', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
]


async function onSearch() {
    await loadData()
}

const data = ref([])
const grid = ref(null)

const contextMenuItems = (params) => {
    return [
        {
            name: 'მთავარი პრიზის გაცემა',
            action: async () => {
                if(params.node.data.is_main_prize) {
                    if(confirm('გაიცეს მთავარი პრიზი?')) {
                        await request.postRequest('/api/lottery/give_main_prize', {id: params.node.data.id })
                        await loadData();
                    }
                }
            }
        }
    ]
}

async function loadData() {
    grid.value.showLoading()
    const response = await request.postRequest(
        '/api/companyportal/get_won_lottery_report', {
        start_date: store.getters.getPicker.dateFrom,
        end_date: store.getters.getPicker.dateTo
    })

    if (response.status === 200) {
        data.value = response.data
    }

    if (grid.value != null) { grid.value.hideLoading() }
}

function onExport() {
    grid.value.excelExport()
}

onMounted(() => {
    setTimeout(async () => await loadData(), 300)
})
</script>
<template>
    <div class="container-fluid">
        <div class="row">
            <div class="offset-md-4 col-md-4 col-sm-6 offset-sm-3">
                <div class="card" style="margin-top: 10%">

                    <div class="card-body">
                        <h5 class="card-title text-center">სისტემაში შესვლა</h5>
                        <div class="alert alert-danger" v-show="message !== ''">
                            {{ message }}
                        </div>
                        <form class="p-2 mt-3">
                            <fieldset>
                                <div class="mb-3">
                                    <label for="id_email" class="form-label">შესვლის სახელი:</label>
                                    <input class="form-control form-control-lg" v-model="loginModel.email" autocomplete="on"
                                        id="id_email" type="email">
                                </div>
                                <div class="mb-3">
                                    <label for="id_password" class="form-label">პაროლი</label>
                                    <input class="form-control form-control-lg" type="password"
                                        v-model="loginModel.password" autocomplete="off" id="id_password"
                                        @keyup.enter="signIn">
                                </div>

                                <div class="d-grid gap-2 mt-2">
                                    <button class="btn btn-lg btn-primary d-md-block" @click="signIn">შესვლა</button>
                                </div>

                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import request from '../http/request'
import { reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()

const loginModel = reactive({ email: null, password: null })
const message = ref('')

async function signIn() {
    message.value = ''
    const userResponse = await request.postRequest(
        '/api/companyportal/sign_in',
        {
            email: loginModel.email,
            password: loginModel.password
        }
    )

    if (userResponse && userResponse.request.status === 200) {
        store.dispatch('setCurrentUser', userResponse.data)

        router.push({name: 'main'})
    } else {
        message.value = 'შესვლის სახელი ან პაროლი არასწორია'
    }
}

</script>

<template>
    <div>
        <ToolbarView @search="onSearch" @export="onExport" hide-picker="true" />
        <GridView class="mt-3" ref="grid" :columns="columns" :data="orderData" :import-date="true"
            :context-menu-items="contextMenuItems" />
            <div class="modal fade" tabindex="-1" data-bs-backdrop="static" id="employee_limit_dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <form id="id_form_employee_limit" novalidate>
                        <div class="modal-header">
                            <h5 class="modal-title">თანამშრომელი</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="form-floating mb-3">
                                <input type="number" class="form-control form-control-sm" id="id_employee_limit_count" placeholder="დღიური ლიმიტი" required />
                                <label for="id_employee_limit_count">დღიური ლიმიტი</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="number" class="form-control form-control-sm" id="id_employee_limit_amount" placeholder="თანხის ლიმიტი" required />
                                <label for="id_employee_limit_amount">თანხის ლიმიტი</label>
                            </div>
                            <div class="form-check form-switch mb-3">
                                <input class="form-check-input" type="checkbox" role="switch" id="id_employee_is_staff">
                                <label class="form-check-label" for="id_employee_is_staff">აქტიური</label>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-success" @click="saveEmployeeLimit">შენახვა</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import * as bootstrap from 'bootstrap'

import request from '../../http/request'
import ToolbarView from '../../components/ToolbarView.vue'
import GridView from '../../components/GridView.vue'

const columns = [
    { headerName: 'სახელი, გვარი', field: 'name', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
    { headerName: 'ტელეფონი', field: 'phone', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
    { headerName: 'ელ-ფოსტა', field: 'email', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
    {
        headerName: 'სქესი',
        field: 'sex',
        cellDataType: 'text',
        cellClass: 'stringType',
        resizable: true,
        sortable: true,
        valueGetter: params => {
            let type = 'სხვა'
            switch (params.data.sex) {
                case 0:
                    type = 'მამრობითი'
                    break
                case 1:
                    type = 'მდედრობითი'
                    break
            }

            return type
        }
    },
    {
        headerName: 'დაბ. თარიღი',
        field: 'birth_date',
        cellDataType: 'dateString',
        cellClass: 'dateType',
        resizable: true,
        sortable: true,
        valueGetter: params => (params.data.birth_date === null || params.data.birth_date === '') ? '' : params.data.birth_date.split('T')[0]
    },
    { headerName: 'თანამშ.', field: 'is_staff', cellDataType: 'boolean', cellClass: 'booleanType', resizable: true, sortable: true },
    { headerName: 'რაოდ. ლიმიტი', field: 'order_limit_count', cellDataType: 'number', cellClass: 'numberType', resizable: true, sortable: true, type: 'rightAligned' },
    { headerName: 'თანხ. ლიმიტი', field: 'order_limit_amount', cellDataType: 'number', cellClass: 'numberType', resizable: true, sortable: true, type: 'rightAligned' },
    { headerName: 'ბარათი', field: 'card_code', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
    { headerName: 'ქულა', field: 'bonus_count', cellDataType: 'number', cellClass: 'numberType', resizable: true, sortable: true, type: 'rightAligned' },
    { headerName: 'რეფერალი', field: 'referral_name', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
    {
        headerName: 'რეგ. თარიღი',
        field: 'create_date',
        cellDataType: 'dateString',
        cellClass: 'dateType',
        resizable: true,
        sortable: true,
        valueGetter: params => params.data.create_date.split('T')[0]
    },
    {
        headerName: 'შესვლის დრო',
        field: 'last_enter_date',
        cellDataType: 'date',
        cellClass: 'dateType',
        resizable: true,
        sortable: true,
        valueFormatter: (params) => {
            if (params.data.last_enter_date === null || params.data.last_enter_date === '0001-01-01T00:00:00') return ''
            const date = new Date(params.data.last_enter_date)
            const day = date.getDate().toString().padStart(2, '0')
            const month = (date.getMonth() + 1).toString().padStart(2, '0')
            const year = date.getFullYear()

            const hourNum = date.getHours()

            const hour = (hourNum === 0 ? 0 : hourNum).toString().padStart(2, '0')
            const min = date.getMinutes().toString().padStart(2, '0')

            return year + '-' + month + '-' + day + ' ' + hour + ':' + min
        }
    },
    { headerName: 'დაბლოკილი', field: 'is_blocked', cellDataType: 'boolean', cellClass: 'booleanType', resizable: true, sortable: true },
]

const orderData = ref([])
const grid = ref(null)

let employeeLimitModal, employeeLimitCountField, employeeLimitAmountField, employeeIsStaffField;
let _id = 0

async function loadData () {
    grid.value.showLoading()
    const response = await request.postRequest(
        '/api/companyportal/get_customers_report')

    if (response.status === 200) {
        orderData.value = response.data
    }

    if (grid.value != null) { grid.value.hideLoading() }
}

async function onSearch () {
    await loadData()
}

function onExport () {
    grid.value.excelExport()
}

const contextMenuItems = (params) => {
    let menu = [];

    _id = params.node.data.id

    if (!params.node.data.is_blocked) {
        menu.push({
            name: 'დაბლოკვა',
            action: async () => await blockUnblock(params.node.data.id, true)
        });
    }else {
        menu.push({
            name: 'ბლოკის მოხსნა',
            action: async () => await blockUnblock(params.node.data.id, false)
        });
    }

    menu.push({
        name: 'თანამშრომელი',
        action: async () => await employeeLimit(params.node.data.is_staff, params.node.data.order_limit_count, params.node.data.order_limit_amount)
    });

    return menu;
}

async function blockUnblock (id, sign) {
    if (confirm('ნამდვილად გსურთ მომხმარებლის ' + (!sign ? 'განბლოკვა' : 'დაბლოკვა'))) {
        const response = await request.postRequest(
            '/api/companyportal/customer_block_unblock', { id, sign })

        if (response.status === 200) {
            await loadData()
        }
    }
}

async function employeeLimit(isStaff, orderLimitCount, orderLimitAmount) {
    employeeLimitCountField = document.querySelector('#id_employee_limit_count')
    employeeLimitCountField.value = orderLimitCount

    employeeLimitAmountField = document.querySelector('#id_employee_limit_amount')
    employeeLimitAmountField.value = orderLimitAmount

    employeeIsStaffField = document.querySelector('#id_employee_is_staff')
    employeeIsStaffField.value = isStaff

    employeeLimitModal = new bootstrap.Modal('#employee_limit_dialog', {})
    employeeLimitModal.show()
}

async function saveEmployeeLimit() {
    const response = await request.postRequest(
            '/api/companyportal/set_staff_customer', { 
                id: _id,
                is_staff: document.querySelector('#id_employee_is_staff').checked,
                order_limit_count: parseFloat(document.querySelector('#id_employee_limit_count').value),
                order_limit_amount: parseFloat(document.querySelector('#id_employee_limit_amount').value)
            })

        if (response.status === 200) {
            employeeLimitModal.hide()
            await loadData()
        }
}

onMounted(() => {
    setTimeout(async () => await loadData(), 300)
})
</script>

<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex flex-row justify-content-between shadow-sm p-2 bg-body-tertiary rounded">
                    <button type="button" class="btn btn-success" @click="openSubsidyDialog(-1)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-plus-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path
                                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        ახალი სიბსიდია
                    </button>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-12">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">საწყისი თანხა</th>
                            <th scope="col">საბოლოო თანხა</th>
                            <th scope="col">პროცენტი</th>
                            <th scope="col" style="width: 15px;"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="subsidy in subsidiesData" :key="subsidy.id" @click="openSubsidyDialog(subsidy.id)"
                            style="cursor: pointer;">
                            <td>{{ subsidy.start_amount }}</td>
                            <td>{{ subsidy.end_amount }}</td>
                            <td>{{ subsidy.discount }}</td>
                            <td style="text-align: center;">
                                <button type="button" class="btn btn-danger btn-sm" @click.stop="deleteSubsidy(subsidy.id)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-trash" viewBox="0 0 16 16">
                                        <path
                                            d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                        <path
                                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                    </svg>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="modal fade modal-lg" tabindex="-1" data-bs-backdrop="static" id="subsidy_dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <form id="id_form_branch" novalidate>
                        <div class="modal-header">
                            <h5 class="modal-title">მიწოდების სუბსიდია</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="form-floating mb-3 mt-3">
                                <input type="text" class="form-control form-control-sm" id="id_start_amount"
                                    placeholder="საწყისი თანხა" v-model="subsidy.start_amount" required />
                                <label for="id_start_amount">საწყისი თანხა</label>
                            </div>
                            <div class="form-floating mb-3 mt-3">
                                <input type="text" class="form-control form-control-sm" id="id_end_amount"
                                    placeholder="საბოლოო თანხა" v-model="subsidy.end_amount" required />
                                <label for="id_end_amount">საბოლოო თანხა</label>
                            </div>
                            <div class="form-floating mb-3 mt-3">
                                <input type="text" class="form-control form-control-sm" id="id_discount"
                                    placeholder="პროცენტი (%)" v-model="subsidy.discount" required />
                                <label for="id_discount">პროცენტი (%)</label>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-success" @click="saveSubsidy">შენახვა</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'

import request from '../../http/request'

import * as bootstrap from 'bootstrap'

const store = useStore()

const subsidiesData = ref([])

let subsidyModal

const subsidyObj = {
    id: 0,
    company_id: store.getters.getCompanyId,
    start_amount: null,
    end_amount: null,
    discount: null,
}

const subsidy = reactive({ ...subsidyObj })

async function loadData() {
    const response = await request.postRequest('/api/companyportal/get_delivery_subsidies', { company_id: store.getters.getCompanyId })

    if (response.status === 200) {
        subsidiesData.value = response.data
    }
}

async function openSubsidyDialog(subsidyId) {
    if (subsidyId === -1) {
        Object.assign(subsidy, subsidyObj);
        subsidyModal = new bootstrap.Modal('#subsidy_dialog', {})
        subsidyModal.show()
    }
    else {
        const response = await request.postRequest(
            '/api/companyportal/get_delivery_subsidy', { id: subsidyId })
        if (response.status === 200) {
            Object.assign(subsidy, response.data);
            subsidyModal = new bootstrap.Modal('#subsidy_dialog', {})
            subsidyModal.show()
        }
    }
}

async function saveSubsidy() {
    const response = await request.postRequest(
        '/api/companyportal/save_delivery_subsidy', subsidy)

    if (response.status === 200) {
        await loadData()
        subsidyModal.hide()
    }
}

async function deleteSubsidy(subsidyId) {
    const yesNo = confirm('წაიშალოს ჩანაწერი?')
    if (yesNo) {
        
        const response = await request.postRequest('/api/companyportal/delete_delivery_subsidy', { id: subsidyId })

        if (response.status === 200) {
            await loadData()
        }
    }
}

onMounted(async () => {
    await loadData()
})
</script>
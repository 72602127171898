<template>
    <div>
        <ToolbarView @search="onSearch" @export="onExport" />
        <GridView class="mt-3" ref="grid" :columns="columns" :data="orderData" :context-menu-items="contextMenuItems" />
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'

import request from '../../http/request'
import ToolbarView from '../../components/ToolbarView.vue'
import GridView from '../../components/GridView.vue'

const store = useStore()

const orderData = ref([])
const grid = ref(null)

const columns = [
    { headerName: 'ნომერი', field: 'num', cellDataType: 'text', cellClass: 'stringType', resizable: true },
    {
        headerName: 'შეკვეთის დრო',
        field: 'tdate',
        cellDataType: 'date',
        cellClass: 'dateType',
        resizable: true,
        sortable: true,
        valueFormatter: (params) => {
            const date = new Date(params.data.tdate)
            const day = date.getDate().toString().padStart(2, '0')
            const month = (date.getMonth() + 1).toString().padStart(2, '0')
            const year = date.getFullYear()

            const hourNum = date.getHours()

            const hour = (hourNum === 0 ? 0 : hourNum).toString().padStart(2, '0')
            const min = date.getMinutes().toString().padStart(2, '0')

            return year + '-' + month + '-' + day + ' ' + hour + ':' + min
        }
    },
    { headerName: 'მომხმარებელი', field: 'customer_name', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
    { headerName: 'ბარათი', field: 'card_code', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
    { headerName: 'თანამშრ.', field: 'is_employee', cellDataType: 'boolean', cellClass: 'booleanType', resizable: true },
    { headerName: 'ასაკი', field: 'age', cellDataType: 'number', cellClass: 'numberType', resizable: true, type: 'rightAligned' },
    {
        headerName: 'სქესი',
        field: 'sex',
        cellDataType: 'text',
        cellClass: 'stringType',
        resizable: true,
        sortable: true,
        valueGetter: params => {
            let type = 'სხვა'
            switch (params.data.sex) {
                case 0:
                    type = 'მამრობითი'
                    break
                case 1:
                    type = 'მდედრობითი'
                    break
            }

            return type
        }
    },
    { headerName: 'ტელეფონი', field: 'phone', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
    { headerName: 'ფილიალი', field: 'branch_name', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
    { headerName: 'ტრანზ. ქულა', field: 'transaction_bonus', cellDataType: 'number', cellClass: 'numberType', resizable: true, sortable: true, type: 'rightAligned' },
    { headerName: 'ღირებულება', field: 'amount', cellDataType: 'number', cellClass: 'numberType', resizable: true, sortable: true, type: 'rightAligned' },
    {
        headerName: 'ტიპი',
        field: 'order_type',
        cellDataType: 'text',
        cellClass: 'stringType',
        resizable: true,
        sortable: true,
        valueGetter: params => {
            let type = ''
            switch (params.data.order_type) {
                case 0:
                    type = 'Dine In'
                    break
                case 1:
                    type = 'Take Away'
                    break
                case 2:
                    type = 'Delivery'
                    break
                case 4:
                    type = 'Curbside'
                    break
            }

            return type
        }
    },
    {
        headerName: 'რეგ. თარიღი',
        field: 'create_date',
        cellDataType: 'dateString',
        cellClass: 'dateType',
        resizable: true,
        sortable: true,
        valueGetter: params => params.data.create_date.split('T')[0]
    },
    {
        headerName: 'შესვლის დრო',
        field: 'last_enter_date',
        cellDataType: 'date',
        cellClass: 'dateType',
        resizable: true,
        sortable: true,
        valueFormatter: (params) => {
            if (params.data.last_enter_date === null || params.data.last_enter_date === '0001-01-01T00:00:00') return ''
            const date = new Date(params.data.last_enter_date)
            const day = date.getDate().toString().padStart(2, '0')
            const month = (date.getMonth() + 1).toString().padStart(2, '0')
            const year = date.getFullYear()

            const hourNum = date.getHours()

            const hour = (hourNum === 0 ? 0 : hourNum).toString().padStart(2, '0')
            const min = date.getMinutes().toString().padStart(2, '0')

            return year + '-' + month + '-' + day + ' ' + hour + ':' + min
        }
    },
]

async function loadData () {
    grid.value.showLoading()
    const response = await request.postRequest(
        '/api/companyportal/get_orders_with_customers_report',
        {
            company_id: store.getters.getCompanyId,
            start_date: store.getters.getPicker.dateFrom,
            end_date: store.getters.getPicker.dateTo
        })

    if (response.status === 200) {
        orderData.value = response.data
    }
    if (grid.value != null) { grid.value.hideLoading() }
}

async function onSearch () {
    await loadData()
}

function onExport () {
    grid.value.excelExport()
}

onMounted(() => {
    setTimeout(async () => await loadData(), 300)
})


</script>
<template>
    <div>
    <div class="row">
        <div class="col-md-12">
            <div class="d-flex flex-row justify-content-between shadow-sm p-2 bg-body-tertiary rounded">
                <button type="button" class="btn btn-success" @click="openUserDialog(-1)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-plus-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path
                            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                    ახალი მომხმარებელი
                </button>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-12">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">დასახელება</th>
                        <th scope="col">ელ-ფოსტა</th>
                        <th scope="col">მობილური</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="user in usersData" :key="user.id" @click="openUserDialog(user.id)" style="cursor: pointer;">
                        <td>{{ user.name }}</td>
                        <td>{{ user.email }}</td>
                        <td>{{ user.phone }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal fade modal-lg" tabindex="-1" data-bs-backdrop="static" id="user_dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <form id="id_form_branch" novalidate>
                    <div class="modal-header">
                        <h5 class="modal-title">მომხმარებელი</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <ul class="nav nav-tabs">
                            <li class="nav-item">
                                <button class="nav-link active" id="main-tab" data-bs-toggle="tab"
                                    data-bs-target="#main-tab-panel" type="button" role="tab" aria-controls="main-tab-panel"
                                    aria-selected="true">ძირითადი</button>
                            </li>
                            <li class="nav-item">
                                <button class="nav-link" id="privilegies-tab" data-bs-toggle="tab"
                                    data-bs-target="#privilegies-tab-panel" type="button" role="tab"
                                    aria-controls="privilegies-tab-panel" aria-selected="false">პრივილეგიები</button>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane fade show active" id="main-tab-panel" role="tabpanel"
                                aria-labelledby="main-tab" tabindex="0">
                                <div class="form-floating mb-3 mt-3">
                                    <input type="text" class="form-control form-control-sm" id="id_name"
                                        placeholder="დასახელება" v-model="user.name" required />
                                    <label for="id_name">დასახელება</label>
                                </div>
                                <div class="form-floating mb-3 mt-3">
                                    <input type="text" class="form-control form-control-sm" id="id_name"
                                        placeholder="დასახელება" v-model="user.email" required />
                                    <label for="id_name">ელ-ფოსტა</label>
                                </div>
                                <div class="form-floating mb-3 mt-3">
                                    <input type="text" class="form-control form-control-sm" id="id_name"
                                        placeholder="დასახელება" v-model="user.phone" required />
                                    <label for="id_name">მობილური</label>
                                </div>
                                <div class="form-floating mb-3 mt-3">
                                    <select class="form-control form-control-sm" v-model="user.company_id" required>
                                        <option v-for="company in companies" :value="company.id" :key="company.id">{{
                                            company.name
                                        }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="tab-pane fade active" id="privilegies-tab-panel" role="tabpanel"
                                aria-labelledby="privilegies-tab" tabindex="1">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>პრივილეგია</th>
                                            <th>ნახვა</th>
                                            <th>რედაქტირება</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(permision, index) in user.permisions" :key="index">
                                            <td>{{ permision.label }}</td>
                                            <td><input type="checkbox" v-model="permision.can_view" /></td>
                                            <td><input type="checkbox" v-model="permision.can_change" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-success" @click="saveUser">შენახვა</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
</template>


<script setup>
import { onMounted, reactive, ref } from 'vue'
import * as bootstrap from 'bootstrap'
import request from '../../http/request'



let userModal
const usersData = ref([])
const companies = ref([])

const userObject = {
    id: 0,
    name: "",
    phone: "",
    email: "",
    password: "",
    permisions: [],
}

const user = reactive({ ...userObject })

async function openUserDialog(userId) {
    await loadPermissions();
    if (userId === -1) {
        Object.assign(user, userObject);
        userModal = new bootstrap.Modal('#user_dialog', {})
        userModal.show()
    }
    else {
        const response = await request.postRequest(
            '/api/companyportal/get_user', { id: userId })
        if (response.status === 200) {
            if(response.data.permisions.length < 1){
                await loadPermissions();
                response.data.permisions = userObject.permisions;
            }
            Object.assign(user, response.data);
            userModal = new bootstrap.Modal('#user_dialog', {})
            userModal.show()
        }
    }
}


async function loadData() {
    const response = await request.postRequest(
        '/api/companyportal/get_company_users_for_admin')
    if (response.status === 200) {
        usersData.value = response.data
    }
}
async function loadCompanies() {
    const response = await request.postRequest(
        '/api/companyportal/get_companies_for_admin'
    )
    if (response.status === 200) {
        companies.value = response.data;
    }
}

async function loadPermissions(){
    const response = await request.postRequest(
        '/api/companyportal/get_permissions'
    )
    if(response.status === 200)
    {
        userObject.permisions = response.data;
    }
}


async function saveUser() {
    const response = await request.postRequest(
        '/api/companyportal/add_or_update_user', user)
    if (response.data > 0) {
        await loadData()
        userModal.hide()
    } else {
        alert(response.data.message)
    }
}

onMounted(async () => {
    await loadData()
    await loadCompanies();
})
</script>
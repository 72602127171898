<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex flex-row justify-content-between shadow-sm p-2 bg-body-tertiary rounded">
                    <input type="text" class="form-control form-control-sm"
                                                id="id_products_search" placeholder="პროდუქტის ძებნა" autocomplete="off" />
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-12">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">კოდი</th>
                            <th scope="col">დასახელება</th>
                            <th scope="col" style="width: 15px;"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="product in excludedProductsData" :key="product.id"
                            style="cursor: pointer;">
                            <td>{{ product.code }}</td>
                            <td>{{ product.name }}</td>
                            <td style="text-align: center;">
                                <button type="button" class="btn btn-danger btn-sm" @click.stop="deleteExcludedProduct(product.id)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-trash" viewBox="0 0 16 16">
                                        <path
                                            d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                        <path
                                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                    </svg>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'

import request from '../../http/request'
import { Autocomplete } from '../../components/autocomplete'
import * as bootstrap from 'bootstrap'

const store = useStore()

const excludedProductsData = ref([])
let searchProducts

async function loadData() {
    const response = await request.postRequest('/api/companyportal/get_delivery_exclude_products', { company_id: store.getters.getCompanyId })

    if (response.status === 200) {
        excludedProductsData.value = response.data
    }
}

async function deleteExcludedProduct(id) {
    const yesNo = confirm('წაიშალოს ჩანაწერი?')
    if (yesNo) {
        
        const response = await request.postRequest('/api/companyportal/delete_delivery_excluded_product', { id: id, company_id: store.getters.getCompanyId })

        if (response.status === 200) {
            await loadData()
        }
    }
}

async function addExcludedProduct(id) {
    const response = await request.postRequest('/api/companyportal/add_delivery_excluded_product', { id: id, company_id: store.getters.getCompanyId })
    
    if (response.status === 200) {
        await loadData()
    }
}

async function getSearchData() {
    const response = await request.postRequest('/api/companyportal/get_loyality_search_data', { id: store.getters.getCompanyId })
    if (response.status === 200) {
        searchProducts = response.data.products
    }
}

function makeAutocomplete() {
    setTimeout(() => {
        const productsSearchField = document.querySelector('#id_products_search')
            new Autocomplete(productsSearchField, {
                data: searchProducts.map((p) => ({ 'value': p.id, 'label': p.name, 'subLabel': p.code })),
                maximumItems: 15,
                threshold: 1,
                showSubLabel: true,
                onSelectItem: async ({ label, value }) => {
                    productsSearchField.value = null

                    await addExcludedProduct(parseInt(value))
                }
            })

    }, 500);

}

onMounted(async () => {
    makeAutocomplete();
    await getSearchData()
    await loadData()
})
</script>
<template>
    <div class="container-fluid">
        <nav class="navbar navbar-expand-lg bg-primary" data-bs-theme="dark">
            <div class="container-fluid">
                <div class="collapse navbar-collapse">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    </ul>
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a class="nav-link" href="#" @click="signOut"><small>{{ $store.getters.getUsername }}</small>
                                გასვლა</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        <div class="row mt-4">
            <div class="col-md-12">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col">
                            <a type="button" class="btn btn-primary w-100"
                                style="height: 70px; line-height: 50px; font-size: 20px;" href="#/books">ცნობარები</a>
                        </div>
                        <div class="col">
                            <a type="button" class="btn btn-primary w-100"
                                style="height: 70px; line-height: 50px; font-size: 20px;" href="#/reports">ანგარიშგებები</a>
                        </div>
                        <div class="col">
                            <a type="button" class="btn btn-primary w-100"
                                style="height: 70px; line-height: 50px; font-size: 20px;"
                                href="#/operations/push_notifications">Push
                                შეტყობინებები</a>
                        </div>

                        <div class="col">
                            <a type="button" class="btn btn-primary w-100"
                                style="height: 70px; line-height: 50px; font-size: 20px;"
                                href="#/operations/push_messages">Push მესიჯები</a>
                        </div>

                        <div class="col">
                            <a type="button" class="btn btn-primary w-100"
                                style="height: 70px; line-height: 50px; font-size: 20px;"
                                href="#/operations/lottery">გათამაშება</a>
                        </div>

                        <div class="col">
                            <a type="button" class="btn btn-primary w-100"
                                style="height: 70px; line-height: 50px; font-size: 20px;"
                                href="#/operations/loyality">ლოიალობა</a>
                        </div>

    
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const router = useRouter()
const store = useStore()

function signOut () {
    store.commit('removeCurrentUser')
    router.push({ name: 'login' })
}
</script>

<template>
    <router-view />
</template>

<script setup>
import { onMounted, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()

onBeforeMount(() => {
  const _token = store.getters.getToken
  if (_token === null) {
    router.push({ name: 'login' })
  }
})

onMounted(() => {
  router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!store.getters.signed) {
        next({ name: 'login', query: { redirect: to.fullPath } })
      } else {
        next()
      }
    } else {
      next()
    }
  })
})

</script>

<style>
svg {
    vertical-align: -.125em !important;
}
</style>

<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex flex-row justify-content-between shadow-sm p-2 bg-body-tertiary rounded">
                    <button type="button" class="btn btn-success" @click="onOpenMessage(-1)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-plus-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path
                                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        ახალი მესიჯი
                    </button>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-12">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">დასახელება</th>
                            <th scope="col">დასრულების თარიღი</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(message, index) in messagesData" :key="message.id" @click="onOpenMessage(index)" style="cursor: pointer;">
                            <td>
                                <template v-if="message.type === 0">შეტყობინება</template>
                                <template v-else>წესები და პირობები</template>
                            </td>
                            <td>{{ message.name }}</td>
                            <td>{{ moment(message.end_date).format('YYYY-MM-DD HH:mm') }}</td>
                        </tr>
                        <tr v-if="messagesData.length === 0">
                            <td colspan="2" class="text-center">
                                ჩანაწერები ვერ მოიძებნა
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="modal fade modal-lg" tabindex="-1" data-bs-backdrop="static" id="message_dialog">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <form id="id_form_branch" novalidate>
                                <div class="modal-header">
                                    <h5 class="modal-title">ფილიალი</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <div class="form-floating mb-3 mt-3">
                                        <select class="form-control form-control-sm" id="id_type" v-model="message.type" required>
                                            <option :value="0">შეტყობინება</option>
                                            <option :value="1">წესები და პირობები</option>
                                        </select>
                                        <label for="id_type">ტიპი</label>
                                    </div>
                                    <div class="form-floating mb-3 mt-3">
                                        <input type="text" class="form-control form-control-sm" id="id_name"
                                            placeholder="დასახელება" v-model="message.name" required />
                                        <label for="id_name">დასახელება</label>
                                    </div>
                                    <div class="form-floating mb-3 mt-3">
                                        <EmojiPicker :native="true" pickerType="textarea" id="id_text" required />
                                    </div>
                                    <div class="form-floating">
                                        <input type="date" class="form-control form-control-sm" id="id_end_date"
                                                placeholder="დასრულების დრო" v-model="message.end_date" required />
                                            <label for="id_end_date">დასრულების დრო</label>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="submit" class="btn btn-success" @click="saveMessage">შენახვა</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { onMounted, ref, reactive } from 'vue'

import request from '../../http/request'
import moment from 'moment'
import * as bootstrap from 'bootstrap'
import EmojiPicker from 'vue3-emoji-picker'

const messagesData = ref([])

let messageField, messageModal
const messageObj = {
    id: 0,
    name: null,
    text: null,
    type: 0,
    end_date: null
}

const message = reactive({ ...messageObj })

async function onOpenMessage(index) {

    messageModal = new bootstrap.Modal('#message_dialog', {})

    if (index === -1) {
        messageField.value = ''
        Object.assign(message, messageObj);
        messageModal.show()
    } else {
        const response = await request.postRequest(
            '/api/companyportal/get_push_message', { id: messagesData.value[index].id })

        if (response.status === 200) {

            response.data.end_date = moment(response.data.end_date).format('YYYY-MM-DD')

            messageField.value = response.data.text

            Object.assign(message, response.data);

            messageModal.show()
        }
    }
}

async function saveMessage() {
    if(confirm('ნამდვილად გსურს შენახვა?')) {
        message.text = messageField.value

        const response = await request.postRequest(
        '/api/companyportal/add_or_update_message', message)
        if (response.data > 0) {
            await loadData()
            messageModal.hide()
        }
    }
}

async function loadData() {
    const response = await request.postRequest(
        '/api/companyportal/get_all_push_message')

    if (response.status === 200) {
        messagesData.value = response.data
    }
}


onMounted(async () => {
    messageField = document.querySelector('#id_text textarea')
    messageField.classList.add('form-control')
    messageField.classList.add('form-control-sm')
    messageField.placeholder = 'შეტყობინება'
    messageField.classList.remove('v3-emoji-picker-textarea')
    await loadData()
})

</script>
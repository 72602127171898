<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex flex-row justify-content-between shadow-sm p-2 bg-body-tertiary rounded">
                    <input class="form-control" type="file" id="id_file"
                        accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        style="width: 400px;">

                    <template v-if="phones.length > 0">
                        <button type="button"  class="btn btn-primary" @click="sendMessage" v-if="isSending" disabled>გაგზავნა</button>
                        <button type="button"  class="btn btn-primary" @click="sendMessage" v-else>გაგზავნა</button>
                    </template>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-6">
                <label class="form-label">მიმღები</label>
                <ul class="list-group list-group-flush" style="height: 82vh; overflow-y: auto;">
                    <li class="list-group-item" v-for="phone in phones" :key="phone">{{ phone }}</li>
                </ul>
            </div>
            <div class="col-md-6">
                <label class="form-label">შეტყობინება</label>
                <input class="form-control" type="text" placeholder="სათაური" v-model="title">
                <EmojiPicker :native="true" pickerType="textarea" id="id_emoji" />
                <div class="row">
                    <div class="col-md-12 mt-3">
                        <ul class="list-group list-group-flush" style="height: 68vh; overflow-y: auto;">
                            <li class="list-group-item d-flex justify-content-between align-items-start">
                                <div class="ms-2 me-auto">
                                    სულ ჯამი
                                </div>
                                <span class="badge bg-primary rounded-pill">{{ tokens.totalCount }}</span>
                            </li>
                            <li class="list-group-item" v-for="(res, i) in tokens.result" v-bind:key="i">
                                <div class="d-flex w-100 justify-content-between">
                                    <h5 class="mb-1">გაიგზავნა</h5>
                                    <small class="text-body-secondary fw-bold">{{ res.total }}</small>
                                </div>
                                <div class="mt-1 d-flex w-100 justify-content-between">
                                    <span>წარმატებული</span>
                                    <small class="text-body-secondary text-success-emphasis fw-bold">{{ res.success }}</small>
                                </div>
                                <div class="d-flex w-100 justify-content-between">
                                    <span>უარყოფილი</span>
                                    <small class="text-body-secondary text-danger-emphasis fw-bold">{{ res.failure }}</small>
                                </div>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import request from '../../http/request'
import * as XLSX from 'xlsx/dist/xlsx.full.min.js'
import EmojiPicker from 'vue3-emoji-picker'
import {HubConnectionBuilder} from '@microsoft/signalr'

import { ref, onMounted, onDeactivated } from 'vue'

import 'vue3-emoji-picker/css'

const phones = ref([])
const title = ref(null)
const isSending = ref(false)

let messageField = null
let fileField = null

let socketConnection;

const tokens = ref({
    totalCount: 0,
    result: []
})

function onFileChange (e) {
    const file = e.target.files || e.dataTransfer.files
    if (!file.length) return

    const reader = new FileReader()
    reader.readAsBinaryString(file[0])

    reader.onload = (e) => {
        const data = e.target.result
        const workbook = XLSX.read(data, {
            type: 'binary'
        })

        const rowObject = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[workbook.SheetNames[0]])
        phones.value = rowObject.map(c => c['ტელეფონი'].toString())

        tokens.value.totalCount = phones.value.length
    }
}

async function sendMessage () {
    if(isSending.value) return

    tokens.value.totalCount = 0
    tokens.value.result.length = 0

    await socketConnection.start()
    isSending.value = true

    await request.postRequest(
        '/api/companyportal/send_push_notifications', { customers: phones.value, text: messageField.value, title: title.value })

    phones.value = []
    title.value = null
    messageField.value = null
    fileField.value = null

    await socketConnection.stop()

    isSending.value = false

    alert('ოპერაცია დასრულდა')
}

onMounted(async () => {
    messageField = document.querySelector('#id_emoji textarea')
    messageField.classList.add('form-control')
    messageField.classList.add('mt-2')
    messageField.classList.remove('v3-emoji-picker-textarea')

    fileField = document.querySelector('#id_file')
    fileField.addEventListener('change', onFileChange)

    socketConnection = new HubConnectionBuilder().withUrl('/pushMessagesHub').withAutomaticReconnect().build();
    socketConnection.on('GetSentMessages', (data) => {
        tokens.value.result.push({
            total: data.responses.length,
            failure: data.failureCount,
            success: data.successCount
        })
    })
})

onDeactivated(async () => {
    socketConnection.stop();
    socketConnection = null;
});
</script>

import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  state: {
    user: null,
    picker: {
      dateFrom: null,
      dateTo: null
    }
  },
  getters: {
    getToken: state => state.user !== null ? state.user.token : null,
    getUsername: state => state.user !== null ? state.user.name : '',
    signed: state => state.user !== null && state.user.token !== null,
    getCompanyId: state => state.user !== null ? state.user.company_id : 0,
    getPicker: state => state.picker
  },
  mutations: {
    setCurrentUser (state, value) {
      state.user = value
    },
    removeCurrentUser (state) {
      state.user = null
    },
    setPickerFrom (state, dateFrom) {
      state.picker.dateFrom = dateFrom
    },
    setPickerTo (state, dateTo) {
      state.picker.dateTo = dateTo
    }
  },
  actions: {
    setCurrentUser ({ commit }, value) {
      commit('setCurrentUser', value)
    },
    removeCurrentUser ({ commit }, value) {
      commit('removeCurrentUser', value)
    },
    setPickerFrom ({ commit }, dateFrom) {
      commit('setPickerFrom', dateFrom)
    },
    setPickerTo ({ commit }, dateTo) {
      commit('setPickerTo', dateTo)
    }
  },
  modules: {
  },
  plugins: [createPersistedState({ key: 'elco' })]
})

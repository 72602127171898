<template>
    <div>
        <ToolbarView @search="onSearch" @export="onExport" />
        <GridView class="mt-3" ref="grid" :columns="columns" :data="data" :import-date="true" />
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'

import request from '../../http/request'
import ToolbarView from '../../components/ToolbarView.vue'
import GridView from '../../components/GridView.vue'

const store = useStore()

const columns = [
    { headerName: 'გათამაშება', field: 'lottery_name', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
    { headerName: 'ტრანზაქცია', field: 'num', cellDataType: 'number', cellClass: 'numberType', resizable: true, sortable: true, type: 'rightAligned' },
    { headerName: 'მიზეზი', field: 'type', cellDataType: 'text', resizable: true, cellClass: 'stringType', },
]


async function onSearch() {
    await loadData()
}

const data = ref([])
const grid = ref(null)

async function loadData() {
    grid.value.showLoading()
    const response = await request.postRequest(
        '/api/companyportal/get_lottery_missed_transactions', {
        start_date: store.getters.getPicker.dateFrom,
        end_date: store.getters.getPicker.dateTo
    })

    if (response.status === 200) {
        data.value = response.data
    }

    if (grid.value != null) { grid.value.hideLoading() }
}

function onExport() {
    grid.value.excelExport()
}

onMounted(() => {
    setTimeout(async () => await loadData(), 300)
})
</script>
<template>
    <div>
        <ToolbarView @search="onSearch" @export="onExport" />
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex shadow-sm p-2 bg-body-tertiary rounded">
                    <CustomerAutocompleteView @selected="onCustomerSelected" style="width: 450px;" />
                    <div class="input-group ms-2" style="width: 550px;">
                        <span class="input-group-text">ფილიალი</span>
                        <select class="form-control" v-model="branchFilter">
                            <option :value="branch.id" v-for="branch in filterBranches" :key="branch.id">{{ branch.name }}</option>
                        </select>
                    </div>
                    <div class="input-group ms-2" style="width: 450px;">
                        <span class="input-group-text">წყარო</span>
                        <select class="form-control" v-model="sourceFilter">
                            <option value="">--ყველა--</option>
                            <option value="ELCO">ELCO</option>
                            <option value="POS">POS</option>
                            <option value="KIOSK">KIOSK</option>
                        </select>
                    </div>
                    <div class="input-group ms-2" style="width: 450px;">
                        <span class="input-group-text">ოპერაცია</span>
                        <select class="form-control" v-model="operationFilter">
                            <option value="-1">--ყველა--</option>
                            <option value="0">დაგროვება</option>
                            <option value="1">გახარჯვა</option>
                            <option value="2">საჩუქარი</option>
                            <option value="3">დაბრუნება</option>
                            <option value="4">გაუქმება</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <GridView class="mt-3" ref="grid" :grid-height="'78vh'" :columns="columns" :data="orderData" />
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'

import request from '../../http/request'
import ToolbarView from '../../components/ToolbarView.vue'
import GridView from '../../components/GridView.vue'
import CustomerAutocompleteView from '@/components/CustomerAutocompleteView'

const store = useStore()

const columns = [
  { headerName: 'შეკვეთის №', field: 'order_num', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
  { headerName: 'ტელეფონი', field: 'phone', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
  {
    headerName: 'თარიღი',
    field: 'tdate',
    cellDataType: 'dateString',
    cellClass: 'dateType',
    resizable: true,
    sortable: true,
    valueFormatter: (params) => {
      const date = new Date(params.data.tdate)
      const day = date.getDate().toString().padStart(2, '0')
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const year = date.getFullYear()

      const hourNum = date.getHours()

      const hour = (hourNum === 0 ? 0 : hourNum).toString().padStart(2, '0')
      const min = date.getMinutes().toString().padStart(2, '0')

      return year + '-' + month + '-' + day + ' ' + hour + ':' + min
    }
  },
  { headerName: 'ქულა', field: 'bonus_point', cellDataType: 'number', cellClass: 'numberType', resizable: true, sortable: true, type: 'rightAligned' },
  { headerName: 'წყარო', field: 'source', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
  {
    headerName: 'ოპერაცია',
    field: 'type',
    cellDataType: 'text',
    cellClass: 'stringType',
    resizable: true,
    sortable: true,
    valueGetter: params => {
      let type = 'სხვა'
      switch (params.data.type) {
        case 0:
          type = 'დაგროვება'
          break
        case 1:
          type = 'გახარჯვა'
          break
        case 2:
          type = 'საჩუქარი'
          break
        case 3:
          type = 'დაბრუნება'
          break
        case 4:
          type = 'გაუქმება'
          break
      }

      return type
    }
  }

]

const orderData = ref([])
const grid = ref(null)
const filterBranches = ref([])

let customerId = 0

const branchFilter = ref(0)
const sourceFilter = ref('')
const operationFilter = ref(-1)

async function loadData () {

  grid.value.showLoading()
  const response = await request.postRequest(
    '/api/companyportal/get_customer_bonus_points',
    {
      id: customerId,
      start_date: store.getters.getPicker.dateFrom,
      end_date: store.getters.getPicker.dateTo,
      filter: {
        'bonus_operation_source': sourceFilter.value,
        'bonus_operation_type': parseInt(operationFilter.value),
        'branch_id': branchFilter.value
      }
    })

  if (response.status === 200) {
    orderData.value = response.data
  }

  if (grid.value != null) { grid.value.hideLoading() }
}

async function onSearch () {
  await loadData()
}

function onExport () {
  grid.value.excelExport()
}

function onCustomerSelected (id) {
  customerId = id.value
}

async function loadInitialData() {
    const response = await request.postRequest(
        '/api/companyportal/get_branches_for_params',
        {
            company_id: store.getters.getCompanyId,
        })

        filterBranches.value = [{'id': 0, 'name': '--ყველა--'}].concat(response.data)
}

onMounted(async () => {
    await loadInitialData();
})

</script>

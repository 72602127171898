<template>
    <div>
        <GridView class="mt-3" ref="grid" :columns="columns" :data="productsData" :import-date="false" :context-menu-items="contextMenuItems" />
        <div class="modal fade" tabindex="-1" data-bs-backdrop="static" id="add_bonus_dialog">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <form id="id_form_bonus_value" novalidate>
                            <div class="modal-header">
                                <h5 class="modal-title">ქულის შეცვლა</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div class="form-floating mb-3">
                                    <input type="number" class="form-control form-control-sm" id="id_point" placeholder="ქულა"
                                        required />
                                    <label for="id_point">ქულა</label>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="submit" class="btn btn-success" @click="editBobusValue">შენახვა</button>
                            </div>
                        </form>
                    </div>
                </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import request from '../../http/request'
import GridView from '../../components/GridView.vue'
import * as bootstrap from 'bootstrap'

import { useStore } from 'vuex'

const store = useStore()

const productsData = ref([])

let _id = 0
let addBonusModal, addBonusField;

const columns = [
    { headerName: 'დასახელება', field: 'name', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
    { headerName: 'კოდი', field: 'code', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
    { headerName: 'ჯგუფი', field: 'group_name', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
    { headerName: 'FinaId', field: 'fina_id', cellDataType: 'number', cellClass: 'numberType', resizable: true, sortable: true },
    { headerName: 'ქულა', field: 'bonus_value', cellDataType: 'number', cellClass: 'numberType', resizable: true, sortable: true },
    { headerName: 'დამალული', field: 'hidden', cellDataType: 'boolean', cellClass: 'booleanType', resizable: true, sortable: true },
]

async function loadData() {
    const response = await request.postRequest(
        '/api/companyportal/get_product_list', {company_id: store.getters.getCompanyId})

    if (response.status === 200) {
        productsData.value = response.data
    }
}

async function editBobusValue () {
    await request.postRequest(
        '/api/companyportal/update_product_bonus_value',
        {
            id: _id,
            bonus: parseInt(addBonusField.value),
        }
    )
    await loadData()
    
    addBonusField.value = 0
    addBonusModal.hide()
}

const contextMenuItems = (params) => {
    let menu = [];

    _id = params.node.data.id

    menu.push({
        name: 'ქულის შეცვლა',
        action: () => {
            addBonusField = document.querySelector('#id_point')
            addBonusField.value = params.node.data.bonus_value

            addBonusModal = new bootstrap.Modal('#add_bonus_dialog', {})
            addBonusModal.show()
        }
    })

    if (params.node.data.hidden) {
        menu.push({
            name: 'გამოჩენა',
            action: async () => await hiddenShow(_id, false)
        });
    }else {
        menu.push({
            name: 'დამალვა',
            action: async () => await hiddenShow(_id, true)
        });
    }

    return menu;
}

async function hiddenShow (id, sign) {
    if (confirm('ნამდვილად გსურთ პროდუქტის ' + (!sign ? 'გამოჩენა' : 'დამალვა'))) {
        const response = await request.postRequest(
            '/api/companyportal/product_hidden_show', { id, is_active: sign })

        if (response.status === 200) {
            await loadData()
        }
    }
}

onMounted(async () => {
    await loadData()
})
</script>
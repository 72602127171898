<template>
    <div class="row">
        <div class="col-md-12">
            <div class="shadow-sm p-2 bg-body-tertiary rounded">
                <ag-grid-vue class="ag-theme-alpine" :style="gridHeight" :columnDefs="props.columns" :rowData="props.data"
                    :defaultColDef="defaultColDef" :excelStyles="excelStyles" :modules="modules" @grid-ready="onGridReady"
                    :loadingOverlayComponent="loadingOverlayComponent"
                    :loadingOverlayComponentParams="loadingOverlayComponentParams" :allowContextMenuWithControlKey="true"
                    :getContextMenuItems="contextMenuItems">
                </ag-grid-vue>
            </div>
        </div>
    </div>
</template>

<script setup>
// eslint-disable-next-line no-unused-vars
import LoadingOverlay from './LoadingOverlay.js'

import { AgGridVue } from 'ag-grid-vue3'
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'
import { MenuModule } from '@ag-grid-enterprise/menu'

import { ref, defineProps, defineExpose } from 'vue'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'

const props = defineProps(['columns', 'data', 'contextMenuItems', 'importDate', 'gridHeight'])

const gridApi = ref(null)

const defaultColDef = {
    sortable: true,
    filter: true,
    flex: 1
}

const gridHeight = ref(props.gridHeight ? 'height:' + props.gridHeight : 'height:81vh')

const excelStyles = [
    {
        id: 'numberType',
        numberFormat: {
            format: '0'
        }
    },
    {
        id: 'booleanType',
        dataType: 'Boolean'
    },
    {
        id: 'stringType',
        dataType: 'String'
    },
    {
        id: 'dateType',
        dataType: 'DateTime',
        numberFormat: {
            format: props.importDate ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm'
        }
    }
]

let loadingOverlayComponent = null
let loadingOverlayComponentParams = null

const modules = [
    ExcelExportModule,
    MenuModule
]

const onGridReady = (params) => {
    gridApi.value = params.api

    loadingOverlayComponent = 'LoadingOverlay'
    loadingOverlayComponentParams = {
        loadingMessage: 'გთხოვთ დაელოდოთ...'
    }
}

const showLoading = () => {
    gridApi.value.showLoadingOverlay()
}

const hideLoading = () => {
    gridApi.value.hideOverlay()
}

const excelExport = () => {
    gridApi.value.exportDataAsExcel()
}

defineExpose({
    excelExport,
    showLoading,
    hideLoading
})

</script>

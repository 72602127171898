import { createRouter, createWebHashHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import MainView from '../views/MainView.vue'
import ReportsView from '../views/ReportsView.vue'
import OperationsView from '../views/OperationsView.vue'
import BooksView from '../views/BooksView.vue'

import CustomersView from '../views/reports/CustomersView.vue'
import DishesDetaledView from '../views/reports/DishesDetaledView.vue'
import ExpensesView from '../views/reports/ExpensesView.vue'
import OrderRatesView from '../views/reports/OrderRatesView.vue'
import OrdersByStatusesView from '../views/reports/OrdersByStatusesView.vue'
import OrdersDetaledView from '../views/reports/OrdersDetaledView.vue'
import OrdersWithCustomersView from '../views/reports/OrdersWithCustomersView.vue'
import RefundedMoneyView from '../views/reports/RefundedMoneyView.vue'
import ServiceTimeView from '../views/reports/ServiceTimeView.vue'
import BonusPointHistoryView from '../views/reports/BonusPointHistoryView.vue'
import CustomerCardsView from '../views/reports/CustomerCardsView.vue'
import WonLotteriesView from '../views/reports/WonLotteriesView.vue'
import LotteryMissedTransactions from '../views/reports/LotteryMissedTransactions.vue'
import FailedTransactions from '../views/reports/FailedTransactions.vue'
import PrePaymentOrders from '../views/reports/PrePaymentOrders.vue'
import QuickShipperLogs from '../views/reports/QuickShipperView.vue'

import PushNotificationsView from '../views/operations/PushNotificationsView.vue'
import PushMessagesView from '../views/operations/PushMessagesView.vue'
import LotteryView from '../views/operations/LotteryView.vue'
import LoyalityVue from '../views/operations/LoyalityVue.vue'

import ProductGroups from '../views/books/ProductGroups.vue'
import ProductsView from '../views/books/ProductsView.vue'
import BranchesView from '../views/books/BranchesView.vue'
import StaticImagesViewVue from '../views/books/StaticImagesView.vue'
import DeliverySubsidies from '../views/books/DeliverySubsidies.vue'
import DeliveryExcludeProducts from '../views/books/DeliveryExcludeProducts.vue'
import UsersView from '@/views/books/UsersView.vue'

const routes = [
    {
        path: '/',
        component: LoginView
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView
    },
    {
        path: '/main',
        name: 'main',
        component: MainView,
        meta: { requiresAuth: true }
    },
    {
        path: '/operations',
        name: 'operations',
        component: OperationsView,
        meta: { requiresAuth: true },
        children: [
            {
                path: '/operations/push_notifications',
                name: 'operations_push_notifications',
                component: PushNotificationsView,
                meta: { requiresAuth: true }
            },
            {
                path: '/operations/push_messages',
                name: 'operations_push_messages',
                component: PushMessagesView,
                meta: { requiresAuth: true }
            },
            {
                path: '/operations/lottery',
                name: 'operations_lottery',
                component: LotteryView,
                meta: { requiresAuth: true }
            },
            {
                path: '/operations/loyality',
                name: 'operations_loyality',
                component: LoyalityVue,
                meta: { requiresAuth: true }
            }
        ]
    },
    {
        path: '/books',
        name: 'books',
        component: BooksView,
        meta: { requiresAuth: true },
        children: [
            {
                path: '/books/product_groups',
                name: 'books_product_groups',
                component: ProductGroups,
                meta: { requiresAuth: true }
            },
            {
                path: '/books/products',
                name: 'books_products',
                component: ProductsView,
                meta: { requiresAuth: true }
            },
            {
                path: '/books/branches',
                name: 'books_branches',
                component: BranchesView,
                meta: { requiresAuth: true }
            },
            {
                path: '/books/static-images',
                name: 'books_static_images',
                component: StaticImagesViewVue,
                meta: {requiresAuth: true},
            },
            {
                path: '/books/delivery_subsidies',
                name: 'books_delivery_subsidies',
                component: DeliverySubsidies,
                meta: {requiresAuth: true},
            },
            {
                path: '/books/delivery_exclude_products',
                name: 'books_delivery_exclude_products',
                component: DeliveryExcludeProducts,
                meta: {requiresAuth: true},
            },
            {
                path: '/books/users',
                name: 'books_users',
                component: UsersView,
                meta: {requiresAuth: true},
            }
        ]
    },
    {
        path: '/reports',
        name: 'reports',
        component: ReportsView,
        meta: { requiresAuth: true },
        children: [
            {
                path: '/reports/customers',
                name: 'reports_customers',
                component: CustomersView,
                meta: { requiresAuth: true }
            },
            {
                path: '/reports/bonus_point_history',
                name: 'reports_bonus_point_history',
                component: BonusPointHistoryView,
                meta: { requiresAuth: true }
            },
            {
                path: '/reports/customer_cards',
                name: 'reports_customer_cards',
                component: CustomerCardsView,
                meta: { requiresAuth: true }
            },
            {
                path: '/reports/dishes_detaled',
                name: 'reports_dishes_detaled',
                component: DishesDetaledView,
                meta: { requiresAuth: true }
            },
            {
                path: '/reports/expenses',
                name: 'reports_expenses',
                component: ExpensesView,
                meta: { requiresAuth: true }
            },
            {
                path: '/reports/order_rates',
                name: 'reports_order_rates',
                component: OrderRatesView,
                meta: { requiresAuth: true }
            },
            {
                path: '/reports/orders_by_statuses',
                name: 'reports_orders_by_statuses',
                component: OrdersByStatusesView,
                meta: { requiresAuth: true }
            },
            {
                path: '/reports/orders_detaled',
                name: 'reports_orders_detaled',
                component: OrdersDetaledView,
                meta: { requiresAuth: true }
            },
            {
                path: '/reports/orders_with_customers',
                name: 'reports_orders_with_customers',
                component: OrdersWithCustomersView,
                meta: { requiresAuth: true }
            },
            {
                path: '/reports/refunded_money',
                name: 'reports_refunded_money',
                component: RefundedMoneyView,
                meta: { requiresAuth: true }
            },
            {
                path: '/reports/service_time',
                name: 'reports_service_time',
                component: ServiceTimeView,
                meta: { requiresAuth: true }
            },
            {
                path: '/reports/won_lotteries',
                name: 'reports_won_lotteries',
                component: WonLotteriesView,
                meta: { requiresAuth: true }
            },
            {
                path: '/reports/lottery_missed_transactions',
                name: 'reports_lottery_missed_transactions',
                component: LotteryMissedTransactions,
                meta: { requiresAuth: true }
            },
            {
                path: '/reports/failed_transactions',
                name: 'reports_failed_transactions',
                component: FailedTransactions,
                meta: { requiresAuth: true }
            },
            {
                path: '/reports/pre_payment_orders',
                name: 'reports_pre_payment_orders',
                component: PrePaymentOrders,
                meta: { requiresAuth: true }
            },
            {
                path: '/reports/quickshipper_logs',
                name: 'reports_quickshipper_logs',
                component: QuickShipperLogs,
                meta: { requiresAuth: true }
            }
        ]
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router

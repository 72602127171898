<template>
    <div class="input-group input-group-sm justify-content-end pickers">
        <div class="input-group-prepend">
            <span class="input-group-text">პერიოდი:</span>
        </div>
        <input type="datetime-local" class="form-control form-control-sm" v-model="pickerModel.dateFrom" />
        <div class="input-group-append">
            <div class="dropdown">
                <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-calendar3" viewBox="0 0 16 16">
                        <path
                            d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                        <path
                            d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                    </svg>
                </button>
                <ul class="dropdown-menu">
                    <li><a class="dropdown-item" @click="dropdownItemClick('today')">დღეს</a></li>
                    <li><a class="dropdown-item" @click="dropdownItemClick('year')">მიმდინარე წელიწადი</a></li>
                    <li>
                        <div class="divider"></div>
                    </li>
                    <li><a class="dropdown-item" @click="dropdownItemClick('jan')">იანვარი</a></li>
                    <li><a class="dropdown-item" @click="dropdownItemClick('feb')">თებერვალი</a></li>
                    <li><a class="dropdown-item" @click="dropdownItemClick('mar')">მარტი</a></li>
                    <li><a class="dropdown-item" @click="dropdownItemClick('apr')">აპრილი</a></li>
                    <li><a class="dropdown-item" @click="dropdownItemClick('may')">მაისი</a></li>
                    <li><a class="dropdown-item" @click="dropdownItemClick('jun')">ივნისი</a></li>
                    <li><a class="dropdown-item" @click="dropdownItemClick('jul')">ივლისი</a></li>
                    <li><a class="dropdown-item" @click="dropdownItemClick('aug')">აგვისტო</a></li>
                    <li><a class="dropdown-item" @click="dropdownItemClick('sep')">სექტემბერი</a></li>
                    <li><a class="dropdown-item" @click="dropdownItemClick('oct')">ოქტომბერი</a></li>
                    <li><a class="dropdown-item" @click="dropdownItemClick('nov')">ნოემბერი</a></li>
                    <li><a class="dropdown-item" @click="dropdownItemClick('dec')">დეკემბერი</a></li>

                </ul>
            </div>
        </div>
        <input type="datetime-local" class="form-control form-control-sm" v-model="pickerModel.dateTo" />
    </div>
</template>

<script setup>
import moment from 'moment'
import { reactive, onMounted, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const pickerModel = reactive({ dateFrom: moment().format('YYYY-MM-DDT00:00'), dateTo: moment().format('YYYY-MM-DDT23:59') })

watch(() => pickerModel.dateFrom, (value) => {
  store.dispatch('setPickerFrom', value)
})

watch(() => pickerModel.dateTo, (value) => {
  store.dispatch('setPickerTo', value)
})

function filterDate (date, isTo) {
  if (isTo) {
    return date.format('YYYY-MM-DDT23:59')
  } else {
    return date.format('YYYY-MM-DDT00:00')
  }
}

function dropdownItemClick (sign) {
  const year = moment().year()
  switch (sign) {
    case 'today':
      pickerModel.dateFrom = filterDate(moment(), false)
      pickerModel.dateTo = filterDate(moment(), true)
      break
    case 'year':
      pickerModel.dateFrom = filterDate(moment([year, 0, 1]), false)
      pickerModel.dateTo = filterDate(moment([year, 11, 31]), true)
      break
    case 'jan':
      pickerModel.dateFrom = filterDate(moment([year, 0, 1]), false)
      pickerModel.dateTo = filterDate(moment([year, 0, 31]), true)
      break
    case 'feb':
      pickerModel.dateFrom = filterDate(moment([year, 1, 1]), false)
      pickerModel.dateTo = filterDate(
        moment([year, 1, moment([year, 1]).daysInMonth()], true)
      )
      break
    case 'mar':
      pickerModel.dateFrom = filterDate(moment([year, 2, 1]), false)
      pickerModel.dateTo = filterDate(moment([year, 2, 31]), true)
      break
    case 'apr':
      pickerModel.dateFrom = filterDate(moment([year, 3, 1]), false)
      pickerModel.dateTo = filterDate(moment([year, 3, 30]), true)
      break
    case 'may':
      pickerModel.dateFrom = filterDate(moment([year, 4, 1]), false)
      pickerModel.dateTo = filterDate(moment([year, 4, 31]), true)
      break
    case 'jun':
      pickerModel.dateFrom = filterDate(moment([year, 5, 1]), false)
      pickerModel.dateTo = filterDate(moment([year, 5, 30]), true)
      break
    case 'jul':
      pickerModel.dateFrom = filterDate(moment([year, 6, 1]), false)
      pickerModel.dateTo = filterDate(moment([year, 6, 31]), true)
      break
    case 'aug':
      pickerModel.dateFrom = filterDate(moment([year, 7, 1]), false)
      pickerModel.dateTo = filterDate(moment([year, 7, 31]), true)
      break
    case 'sep':
      pickerModel.dateFrom = filterDate(moment([year, 8, 1]), false)
      pickerModel.dateTo = filterDate(moment([year, 8, 30]), true)
      break
    case 'oct':
      pickerModel.dateFrom = filterDate(moment([year, 9, 1]), false)
      pickerModel.dateTo = filterDate(moment([year, 9, 31]), true)
      break
    case 'nov':
      pickerModel.dateFrom = filterDate(moment([year, 10, 1]), false)
      pickerModel.dateTo = filterDate(moment([year, 10, 30]), true)
      break
    case 'dec':
      pickerModel.dateFrom = filterDate(moment([year, 11, 1]), false)
      pickerModel.dateTo = filterDate(moment([year, 11, 31]), true)
      break
  }
}

onMounted(() => {
  if (store.getters.getPicker !== null && store.getters.getPicker.dateFrom !== null && store.getters.getPicker.dateTo !== null) {
    pickerModel.dateFrom = store.getters.getPicker.dateFrom
    pickerModel.dateTo = store.getters.getPicker.dateTo
  } else {
    store.dispatch('setPickerFrom', pickerModel.dateFrom)
    store.dispatch('setPickerTo', pickerModel.dateTo)
  }
})

</script>

<style scoped>
.dropdown-menu {
    left: -55px !important;
}

:deep(.pickers) {
    width: 550px;
}

.dropdown-item {
    font-size: 14px !important;
    cursor: pointer;
}</style>

<template>
    <div>
        <div class="row mt-2">
            <div class="col-md-12">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">დასახელება</th>
                            <th scope="col">მისამართი</th>
                            <th scope="col">კომპანია</th>
                            <th scope="col">PosId</th>
                            <th scope="col">აქტიური</th>
                            <th scope="col">ბოლო აქტიური თარიღი</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="branch in branchesData" :key="branch.id" @click="openWorkTimes(branch.id)"
                            style="cursor: pointer;">
                            <td>{{ branch.name }}</td>
                            <td>{{ branch.address }}</td>
                            <td>{{ branch.company_name }}</td>
                            <td>{{ branch.pos_id }}</td>
                            <td style="text-align: center;">
                                <template v-if="branch.is_active">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                                        class="bi bi-check-circle" viewBox="0 0 16 16" style="color:green;">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path
                                            d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                    </svg>
                                </template>
                                <template v-else>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                                        class="bi bi-dash-circle" viewBox="0 0 16 16" style="color:red;">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                                    </svg>
                                </template>
                            </td>
                            <td>
                                <template v-if="branch.last_active_date">
                                    {{ moment(branch.last_active_date).format('YYYY-MM-DD HH:mm:ss') }}
                                </template>
                            </td>
                        </tr>
                        <tr v-if="branchesData.length === 0">
                            <td colspan="5" class="text-center">
                                ჩანაწერები ვერ მოიძებნა
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="modal fade modal-lg" tabindex="-1" data-bs-backdrop="static" id="branch_dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <form id="id_form_branch" novalidate>
                        <div class="modal-header">
                            <h5 class="modal-title">ფილიალი</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <ul class="nav nav-tabs">
                                <li class="nav-item">
                                    <button class="nav-link active" id="main-tab" data-bs-toggle="tab"
                                        data-bs-target="#main-tab-pane" type="button" role="tab"
                                        aria-controls="main-tab-pane" aria-selected="true">ძირითადი</button>
                                </li>
                                <li class="nav-item">
                                    <button class="nav-link" id="worktimes-tab" data-bs-toggle="tab"
                                        data-bs-target="#worktimes-tab-pane" type="button" role="tab"
                                        aria-controls="worktimes-tab-pane" aria-selected="false">სამუშაო საათები</button>
                                </li>
                            </ul>
                            <div class="tab-content">
                                <div class="tab-pane fade show active" id="main-tab-pane" role="tabpanel"
                                    aria-labelledby="main-tab" tabindex="0">
                                    <div class="form-floating mb-3 mt-3">
                                        <input type="text" class="form-control form-control-sm" id="id_quickshipper_address"
                                            placeholder="QuickShipper მისამართი" v-model="branch.quickshipper_address" required />
                                        <label for="id_quickshipper_address">QuickShipper მისამართი</label>
                                    </div>
                                    <div class="form-floating mb-3 mt-3">
                                        <input type="text" class="form-control form-control-sm" id="id_cook_time"
                                            placeholder="დამზადების დრო (წუთი)" v-model="branch.cook_time" required />
                                        <label for="id_cook_time">დამზადების დრო (წუთი)</label>
                                    </div>
                                    <div class="form-floating mb-3 mt-3">
                                        <input type="text" class="form-control form-control-sm" id="id_delivery_time"
                                            placeholder="მიწოდების დრო (წუთი)" v-model="branch.deliver_time" required />
                                        <label for="id_delivery_time">მიწოდების დრო (წუთი)</label>
                                    </div>
                                    <div class="col">
                                        <div class="form-check form-switch mb-3">
                                            <input class="form-check-input" type="checkbox" role="switch" id="id_is_dine_in"
                                                v-model="branch.is_dine_in">
                                            <label class="form-check-label" for="id_is_dine_in">Dine In</label>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-check form-switch mb-3">
                                            <input class="form-check-input" type="checkbox" role="switch"
                                                id="id_is_takeaway" v-model="branch.is_takeaway">
                                            <label class="form-check-label" for="id_is_takeaway">Take Away</label>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-check form-switch mb-3">
                                            <input class="form-check-input" type="checkbox" role="switch"
                                                id="id_is_delivery" v-model="branch.is_delivery">
                                            <label class="form-check-label" for="id_is_delivery">Delivery</label>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-check form-switch mb-3">
                                            <input class="form-check-input" type="checkbox" role="switch" id="id_is_active"
                                                v-model="branch.is_active">
                                            <label class="form-check-label" for="id_is_active">აქტიური</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="worktimes-tab-pane" role="tabpanel"
                                    aria-labelledby="worktimes-tab" tabindex="1">
                                    <div
                                        class="d-flex flex-row justify-content-between shadow-sm p-2 mt-1 bg-body-tertiary rounded" v-if="branch.branch_work_time.length < 7">
                                        <button type="button" class="btn btn-primary btn-sm" @click="addWorkTime">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd"
                                                    d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                            </svg>
                                            დამატება
                                        </button>
                                    </div>
                                    <table class="table table-striped table-sm">
                                        <thead>
                                            <tr>
                                                <th scope="col">დღე</th>
                                                <th scope="col" class="text-center">ტიპი</th>
                                                <th scope="col" class="text-center" style="width: 100px;">დაწყება</th>
                                                <th scope="col" class="text-center" style="width: 100px;">დასრულება</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(worktime, index) in branch.branch_work_time" :key="index"
                                                style="cursor: pointer;">
                                                <td>
                                                    <select class="form-control form-control-sm" v-model="worktime.weekday"
                                                        required>
                                                        <option v-for="(weekDay, index) in weekDays" :value="index"
                                                            :key="index">{{ weekDay }}</option>
                                                    </select>
                                                </td>
                                                <td class="text-center">
                                                    <select class="form-control form-control-sm"
                                                        v-model="worktime.work_type" required>
                                                        <option v-for="(workType, index) in workTypes" :value="index"
                                                            :key="index">{{ workType }}</option>
                                                    </select>
                                                </td>
                                                <td class="text-center">
                                                    <input type="text" v-model="worktime.start_time"
                                                        class="form-control form-control-sm" required />
                                                </td>
                                                <td class="text-center">
                                                    <input type="text" v-model="worktime.end_time"
                                                        class="form-control form-control-sm" required />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-success" @click="saveBranch">შენახვა</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue'
import request from '../../http/request'

import { useStore } from 'vuex'
import moment from 'moment'
import * as bootstrap from 'bootstrap'

const store = useStore()

const branchesData = ref([])

let branchModal

const branchObj = {
    id: 0,
    cook_time: null,
    deliver_time: null,
    is_dine_in: false,
    is_takeaway: false,
    is_delivery: false,
    is_active: false,
    quickshipper_address: null,
    branch_work_time: []
}

const branch = reactive({ ...branchObj })

let workTypes = ref(['პერიოდი', '24/7', 'არასამუშო'])
let weekDays = ref(['კვირა', 'ორშაბათი', 'სამშაბათი', 'ოთხშაბათი', 'ხუთშაბათი', 'პარასკევი', 'შაბათი'])

async function loadData() {
    const response = await request.postRequest(
        '/api/companyportal/get_all_branchs', { company_id: store.getters.getCompanyId })

    if (response.status === 200) {
        branchesData.value = response.data
    }
}

async function openWorkTimes(branchId) {
    const response = await request.postRequest(
        '/api/companyportal/get_branchs_details', { branch_id: branchId })

    if (response.status === 200) {
        Object.assign(branch, response.data);

        branchModal = new bootstrap.Modal('#branch_dialog', {})
        branchModal.show()
    }
}

async function saveBranch() {
    const response = await request.postRequest(
        '/api/companyportal/save_branch_params_details', branch)

    if (response.status === 200) {
        await loadData()
        branchModal.hide()
    }
}

function addWorkTime() {
    if(branch.branch_work_time.length < 8) {
        branch.branch_work_time.push({
            'weekday': 0,
            'work_type': '',
            'start_time': '',
            'end_time': ''
        })
    }
}

onMounted(async () => {
    await loadData()
})
</script>
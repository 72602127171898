<template>
    <div>
        <ToolbarView @search="onSearch" @export="onExport" />
        <GridView class="mt-3" ref="grid" :columns="columns" :data="orderData" />
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'

import request from '../../http/request'
import ToolbarView from '../../components/ToolbarView.vue'
import GridView from '../../components/GridView.vue'

const store = useStore()

const columns = [
  {
    headerName: 'თარიღი',
    field: 'order_date',
    cellDataType: 'dateString',
    cellClass: 'dateType',
    resizable: true,
    sortable: true,
    valueFormatter: (params) => {
      const date = new Date(params.data.order_date)
      const day = date.getDate().toString().padStart(2, '0')
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const year = date.getFullYear()

      const hourNum = date.getHours()

      const hour = (hourNum === 0 ? 0 : hourNum).toString().padStart(2, '0')
      const min = date.getMinutes().toString().padStart(2, '0')

      return year + '-' + month + '-' + day + ' ' + hour + ':' + min
    }
  },
  { headerName: 'რესტორანი', field: 'company', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
  { headerName: 'შეკვეთის №', field: 'order_num', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
  { headerName: 'ღირებულება', field: 'order_amount', cellDataType: 'number', cellClass: 'numberype', resizable: true, sortable: true, type: 'rightAligned' },
  { headerName: 'შეკვეთის დრო', field: 'order_time', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
  { headerName: 'დადასტურების დრო', field: 'approve_time', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
  { headerName: 'სხვაობა', field: 'accept_time', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
  { headerName: 'საჭირო დრო', field: 'cooking_time', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
  { headerName: 'დასრ. დრო', field: 'finish_time', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
  { headerName: 'სერვისის დრო', field: 'service_time', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true },
  { headerName: 'სხვაობა', field: 'diff', cellDataType: 'text', cellClass: 'stringType', resizable: true, sortable: true }
]

const orderData = ref([])
const grid = ref(null)

async function loadData () {
  grid.value.showLoading()
  const response = await request.postRequest(
    '/api/companyportal/get_orders_service_statistic',
    {
      company_id: store.getters.getCompanyId,
      start_date: store.getters.getPicker.dateFrom,
      end_date: store.getters.getPicker.dateTo
    })

  if (response.status === 200) {
    orderData.value = response.data
  }

  if (grid.value != null) { grid.value.hideLoading() }
}

async function onSearch () {
  await loadData()
}

function onExport () {
  grid.value.excelExport()
}

onMounted(() => {
  setTimeout(async () => await loadData(), 300)
})
</script>

<template>
    <div class="row">
        <div class="col-md-12">
            <div class="d-flex flex-row justify-content-between shadow-sm p-2 bg-body-tertiary rounded">
                <div class="d-flex flex-row">
                    <PickerView v-if="!props.hidePicker" />
                    <div v-else></div>
                    <CustomerAutocompleteView v-if="props.hasCustomer" @selected="onCustomerSelected" />
                </div>
                <div>
                    <button type="button" class="btn btn-secondary me-2" @click="$emit('export')">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-file-earmark-excel" viewBox="0 0 16 16">
                            <path
                                d="M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z" />
                            <path
                                d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                        </svg>
                        Excel
                    </button>

                    <button type="button" class="btn btn-success" @click="$emit('search')">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-search" viewBox="0 0 16 16">
                            <path
                                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                        ძებნა
                    </button>

                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import PickerView from '@/components/PickerView'
import CustomerAutocompleteView from '@/components/CustomerAutocompleteView'

import { defineProps, defineEmits } from 'vue'

const props = defineProps(['hidePicker', 'hasCustomer'])
const emit = defineEmits(['customerSelected', 'search', 'export'])

function onCustomerSelected (id) {
  const value = id.value
  emit('customerSelected', { value })
}
</script>

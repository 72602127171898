<template>
    <div class="container-fluid">
        <nav class="navbar navbar-expand-lg bg-primary" data-bs-theme="dark">
            <div class="container-fluid">
                <div class="collapse navbar-collapse">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <a class="nav-link" href="#/main">მთავარი</a>
                        </li>

                    </ul>
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a class="nav-link" href="#" @click="signOut"><small>{{ $store.getters.getUsername }}</small>
                                გასვლა</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        <div class="row">
            <div class="col-md-2">
                <nav class="nav nav-pills flex-column" style="margin-top: 10px;">
                    <li class="nav-item">
                        <a class="nav-link" :class="{ active: $route.name === 'reports_orders_detaled' }"
                            href="#/reports/orders_detaled">შეკვეთები დეტალურად</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="{ active: $route.name === 'reports_orders_with_customers' }"
                            href="#/reports/orders_with_customers">შეკვეთები მყიდველებით</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="{ active: $route.name === 'reports_dishes_detaled' }"
                            href="#/reports/dishes_detaled">კერძები დეტალურად</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="{ active: $route.name === 'reports_orders_by_statuses' }"
                            href="#/reports/orders_by_statuses">შეკვეთები სტატუსებით</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="{ active: $route.name === 'reports_customers' }"
                            href="#/reports/customers">მომხმარებლები</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="{ active: $route.name === 'reports_bonus_point_history' }"
                            href="#/reports/bonus_point_history">ქულების ისტორია</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="{ active: $route.name === 'reports_customer_cards' }"
                            href="#/reports/customer_cards">დაგროვების ბარათები</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="{ active: $route.name === 'reports_order_rates' }"
                            href="#/reports/order_rates">შეკვეთების შეფასებები</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="{ active: $route.name === 'reports_service_time' }"
                            href="#/reports/service_time">სერვისის დრო</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="{ active: $route.name === 'reports_refunded_money' }"
                            href="#/reports/refunded_money">დაბრუნებული თანხები</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="{ active: $route.name === 'reports_reports_expenses' }"
                            href="#/reports/expenses">ხარჯი</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="{ active: $route.name === 'reports_won_lotteries' }"
                            href="#/reports/won_lotteries">მოგებული გათამაშებები</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="{ active: $route.name === 'reports_lottery_missed_transactions' }"
                            href="#/reports/lottery_missed_transactions">გამოტოვებული ტრანზ.-ბი</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="{ active: $route.name === 'reports_failed_transactions' }"
                            href="#/reports/failed_transactions">ბანკის წარუმატებელი ტრანზ.-ბი</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="{ active: $route.name === 'reports_pre_payment_orders' }"
                            href="#/reports/pre_payment_orders">წინასწარი შეკვეთების ლოგი</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="{ active: $route.name === 'reports_quickshipper_logs' }"
                            href="#/reports/quickshipper_logs">Quickshipper ლოგი</a>
                    </li>
                </nav>
            </div>
            <div class="col-md-10">
                <router-view class="mt-2" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const router = useRouter()
const store = useStore()

function signOut() {
    store.commit('removeCurrentUser')
    router.push({ name: 'login' })
}
</script>
